import React, { forwardRef, InputHTMLAttributes, MutableRefObject, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import { AmlApproverValidationRequest, getPage, validateOtp } from "../api/aml-approver-api";
import MiraeLogo from "../asset/mirae-logo";

const OtpNumber = forwardRef( 
    function OtpNumber(
        props: InputHTMLAttributes<HTMLInputElement>, 
        ref: MutableRefObject<HTMLInputElement>) {
    const { ...otherProps } = props;
    return (
      
      <input
            {...otherProps}
            ref={ref}
            type="text"
            style={{
              ...otherProps.style,
              maxWidth: '64px',
              outline: 'none',
              borderLeft: '0px solid #D9D9D9',
              borderRight: '0px solid #D9D9D9',
              borderTop: '0px solid #D9D9D9',
              fontSize: '30px',
              width: '100%', 
              caretColor: 'transparent', 
              textAlign: 'center',
              paddingBottom: '0.5em',
              fontWeight: 700
            }}
            maxLength={1} 
            pattern="[0-9]*" 
            />
      
        )
    }
)

const LoadingSpinner = () => {
  return<><Spinner size={'sm'} /> Menunggu hasil otp</> 
}

const SuccessMessage = ({message}) => {
  return <span style={{color: 'green'}}>{message}</span> 
}
const FailedMessage = ({message}) => {
  return <span style={{color: 'red'}}>{message}</span> 
}

const initOtp = [null, null, null, null, null, null]

const AmlOtpPage = () => {
    let history = useHistory();
    let params = useParams();
    const [pointer, setPointer] = useState(0);
    const [otpArray, setOtpArray] = useState(initOtp);
    const [loading, setLoading] =useState(false);
    const [emailTo, setEmailTo] = useState('');
    const [error, setError] = useState('');
    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState(0);
    const inputRef = useRef(null)

    // const getPage = async (id) => {
    //   return await getPage(id);
    // } 

    useEffect(() => {
      let isMounted = true
      getPage(params.id).then(res => {

          if(isMounted) {
            setEmailTo(res.data.data.emailTo);  
          }
          
        }
      ).catch(err => {
        history.replace('/not-found')
      })
      inputRef.current.focus();
      return () => { isMounted = false };
    },[])

    useEffect(() => {
      if(pointer < 6){
        inputRef.current.focus();
      }
    }, [pointer])

    useEffect(() =>  {
      if(!checkOtpHasNull()) {
        setStatus(0);
        
        setShowStatus(true);
        
        let request :AmlApproverValidationRequest = {
          id: params.id,
          otp: otpArray.join('')
        }
        validateOtp(request).then(async (res) =>  {
          await delayTime(2000)
          setStatus(1);
          await delayTime(2000)
          history.push(`/documentapproval/aml/${params.id}/${res.data.data}`)
        }).catch(async (err) => {
          await delayTime(2000)
          setStatus(2);
        }).finally(
          () => {
            setLoading(false)
          }
        )
      }
    }, [otpArray])

    useEffect(() => {

    }, [showStatus, status])

    let checkOtpHasNull = () => {
      return otpArray.filter(val => val == null).length > 0
    }

    const delayTime = (ms) => {
      return new Promise(
        resolve => setTimeout(resolve, ms)
      )
    }

    const onKeyPress =  (evt, index) => {
      if(!checkOtpHasNull()){
        return
      }
      
      if(evt.charCode > 47 && evt.charCode < 58){
        let value = String.fromCharCode(evt.charCode)
        updateArray(value, index)
        if(pointer < 6){
          setPointer(pointer + 1);
          
        }
      }
    }

    const updateArray = (value, index) => {
      let newOtp = [...otpArray]
      newOtp[index] = value
      setOtpArray(newOtp)
    }
    const popArray = () => {
      const newArray = [...otpArray]; // Create a copy of the array
      for (let i = newArray.length - 1; i >= 0; i--) {
        if (newArray[i] !== null) {
          newArray[i] = null;
          break;
        }
      }
      setOtpArray(newArray); // Update the state with the modified array
    }

    const onKeyDown = (evt, index) => {
      
      if(evt.keyCode === 8 || evt.keyCode === 46){
        setShowStatus(false)
        popArray()
        if(pointer > 0){
          setPointer(pointer - 1);
          
        }
      }
    }

    const onPaste =(event) => {
      event.preventDefault();
      const pastedData = event.clipboardData.getData('text');
      const isSixDigitNumber = /^\d{6}$/.test(pastedData);
    
      if (isSixDigitNumber) {
        let copiedOtp = pastedData.split('');
        setOtpArray(copiedOtp);
        setPointer(5);
        setError(''); // Clear any previous error
      } else {
        setError('Please paste a 6-digit numeric value.');
        setPointer(0);
        setOtpArray(initOtp); // Clear the pasted text if it's invalid
      }

    }
    
    return (
      <Container style={{maxWidth:'658px'}}>
        <Row className="mt-2">
            <Col xs={12}>
            <MiraeLogo />
            </Col>
        </Row>
        <Row className="mt-4" >
          <Col className="mt-2" xs={12} ><span style={{fontSize: '20px', fontWeight: 700}}><strong>OTP</strong></span></Col>
          <Col className="mt-2" xs={12} >
          <span style={{fontSize: '14px'}}>
          Masukkan kode verifikasi yang telah kami kirimkan melalui email ke <strong>{emailTo}</strong>
          </span>
          
          </Col>
          <Col className="mt-4" xs={12}>
            <div className="d-flex px-2 justify-content-center">
            <form
            className="d-flex mw-50 gap-2"

             onSubmit={e => {
              e.preventDefault();
            }}>
                {otpArray.map((e, i) => {
                  return <OtpNumber key={i} name={`digit${i}`} 
                    ref={i===pointer? inputRef: null} 
                    value={otpArray[i] || ""} 
                    readOnly={true}
                    style={{borderBottom: otpArray[i]!= null ? '2px solid #F58220': '2px solid #D9D9D9'}}
                    onKeyPress={e => {onKeyPress(e, i)}} 
                    onKeyDown={e => {onKeyDown(e, i)}}
                    onPaste={e => {onPaste(e)}}
                    />
                })}
            </form>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} className="d-flex justify-content-center" style={{color: "#D9D9D9"}}>
          {status == 0 && showStatus && <LoadingSpinner />}
          {status == 1 && showStatus && <SuccessMessage message={'OTP valid'}/>}
          {status == 2 && showStatus && <FailedMessage message={'OTP gagal'}/>}
          {/* {loading &&  } */}
          </Col>
        </Row>
      </Container>
    )

}

export default AmlOtpPage;