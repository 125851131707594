import React, { useEffect, useState } from "react";
import AmlTab from "./aml-tab";


const items =[{
    id:0,
    title: 'Menunggu Review',
    status: [],
    progress: ['MAKER_ON_PROGRESS'],
    isOpen: true
},{
    id:1,
    title: 'Menunggu Persetujuan',
    status: [],
    progress: ['REVIEWER_ON_PROGRESS'],
    isOpen: false
},{
    id:2,
    title: 'Menunggu Persetujuan Akhir',
    status: [],
    progress: ['APPROVER_ON_PROGRESS'],
    isOpen: false
}, {
    id:3,
    title: 'Disetujui',
    status: ['APPROVE'],
    progress: ['CHECKING_COMPLETE'],
    isOpen: false
}, {
    id:4,
    title: 'Ditolak',
    status: ['REJECT'],
    progress: ['CHECKING_COMPLETE'],
    isOpen: false
}, {
    id:5,
    title: 'Direvisi',
    status: ['REVISE'],
    progress: ['MAKER_ON_PROGRESS'],
    isOpen: false
}]

const AmlTabs =(props) => {
    const [tabs, setTabs] = useState(items)

    useEffect(() => {
    }, [tabs])

    const handleOpenTab = (e) => {

        let changeTab = tabs.map((elm, i) => {
            if(i === e) {
                elm.isOpen = true
            }else {
                elm.isOpen = false
            }
            return elm
        })
        setTabs(changeTab)
        const currentTab = tabs.filter((elm, i) => elm.isOpen === true)
        props.onChange(currentTab[0])
    }

    return (
        <div className="col">
            <ul className='nav nav-tabs'>
                {tabs.map((e, i) => {
                    return <AmlTab 
                        key={e.id} 
                        id={e.id}
                        isOpen={e.isOpen}  
                        title={e.title} 
                        onClick={(e) => handleOpenTab(e)}
                    />
                })}
                    
            </ul>
        </div>
        
    )

}

export default AmlTabs