import React from 'react';
import formatDate from 'app/utils/utils';

const tdStyle = {minWidth: '170px'}
const pxpy4 = "p-4"


const AmlTableHeader = ({ label, sortKey, sort, ascending, onSort }) => {
  const handleSort = () => {
    onSort(sortKey);
  };


  return (
    <th
     
      className={pxpy4}
      style={{ cursor: 'pointer', minWidth: '170px' }}
      onClick={handleSort}
    >
      <div style={{ alignItems: 'center' }} className="">
        <span className="mr-1">{label}</span>
        <span className="mt-1">
          {sort === sortKey && ascending === true && (
            <i className="bx bxs-up-arrow" style={{ fontSize: '10px' }}></i>
          )}
          {sort === sortKey && ascending === false && (
            <i className="bx bxs-down-arrow text-right" style={{ fontSize: '10px' }}></i>
          )}
          {sort !== sortKey && (
            <i className="bx bxs-sort-alt text-right" style={{ fontSize: '14px' }}></i>
          )}
        </span>
      </div>
    </th>
  );
};

const AmlTableRow = ({ item, goToDetail }) => {
  return (
    <tr style={{ background: '#FFF', cursor: 'pointer' }}>
      <td 
        style={tdStyle}
        onClick={() => goToDetail(item)}>
        {formatDate(item?.createdDate, 'DD/MM/YY HH:mm')}
      </td>
      <td 
        style={tdStyle}
        // style={{
        //   wordBreak: 'break-all',
        //   minWidth: '200px',
        //   maxWidth: '200px',
        //   whiteSpace: 'pre-wrap',
        // }}
        onClick={() => goToDetail(item)}
      >
        {item?.customerName}
      </td>
      <td
        style={tdStyle}
        className=""
        // style={{
        //   wordBreak: 'break-all',
        //   minWidth: '200px',
        //   maxWidth: '200px',
        //   whiteSpace: 'pre-wrap',
        // }}
        onClick={() => goToDetail(item)}
      >
        {item?.customerTrend ?? '-'}
      </td>
      <td
        style={tdStyle}
        className=""
        // style={{
        //   wordBreak: 'break-all',
        //   minWidth: '200px',
        //   maxWidth: '400px',
        //   whiteSpace: 'pre-wrap',
        // }}
        onClick={() => goToDetail(item)}
      >
        {item?.recommenderBranch ?? '-'}
      </td>
      <td
        style={tdStyle}
        // style={{
        //   wordBreak: 'break-all',
        //   minWidth: '200px',
        //   maxWidth: '350px',
        //   whiteSpace: 'pre-wrap',
        // }}
      >
        {(Math.round(item?.amlScoring?.score * 100) / 100).toFixed(2)}
      </td>
      <td 
        style={tdStyle}
        onClick={() => goToDetail(item)}>
        {item?.amlRiskStatus}
      </td>
    </tr>
  );
};

const AmlTable = ({ listAnnouncement, loadingList, totalItems, sort, setSort, ascending, setAscending, goToDetail }) => {
  const handleSort = (sortKey) => {
    setSort(sortKey);
    setAscending(prevState => !prevState);
  };

  return (
    // <table className="table table-centered table-nowrap mb-0 table-responsive table-hover"></table>
    <table className="table table-centered table-nowrap mb-0 table-responsive-xl table-hover">
      <thead
        className='thead-light'
        style={{
        //   background: '#fff',
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}
      >
        <tr className='w-100'>
          <AmlTableHeader
            label="Reg Date"
            sortKey="regDate"
            sort={sort}
            ascending={ascending}
            onSort={handleSort}
          />
          <th 
            style={tdStyle}
            className={pxpy4}>Cust Name</th>
          <th 
            style={tdStyle}
            className={pxpy4}>Account Type</th>
          <th 
            style={tdStyle}
            className={pxpy4}>Branch</th>
          <AmlTableHeader
            label="SCORE"
            sortKey="score"
            sort={sort}
            ascending={ascending}
            onSort={handleSort}
          />
          <th 
            style={tdStyle}
            className={pxpy4}>Risk Status</th>
        </tr>
      </thead>
      <tbody style={{ background: '#fff' }}>
        {!loadingList ? (
          totalItems !== 0 ? (
            listAnnouncement.map((item, index) => (
              <AmlTableRow key={index} item={item} goToDetail={goToDetail} />
            ))
          ) : (
            <tr>
              <td colSpan={10} className="text-center">
                Data Not Found
              </td>
            </tr>
          )
        ) : (
          <>
            <tr>
              <td colSpan={8}>
                <div className="row">
                  <div className="shimmerBG"></div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={8}>
                <div className="row">
                  <div className="shimmerBG"></div>
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default AmlTable;