import React, {useState} from "react";
import {
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Input,
    ModalFooter,
    Button,
    Label,
    Container,
  } from 'reactstrap';
import { executeApprover } from "../api/aml-api";


interface ApproverForm {
    notes: string;
}

const initForm: ApproverForm = {
    notes: null,
}

const   AmlModalApprover = ({keyAuth,pageId, stat, isOpen, handleCloseModalApprover, setIsOpenModalApprover, onSubmit}) => {
  const [approverForm, setApproverForm] = useState<ApproverForm>(initForm)

  const handleNoteChange = (e) => setApproverForm({...approverForm, notes: e.target.value})

  const getTitle = (stat) => {
    return stat == "APPROVE" ? "Menyetujui Persetujuan" : stat == "REJECT" ? "Menolak Persetujuan" : "Merevisi Persetujuan"
  }

  const executeApproverAction = async (e) => {
    let amlActionRequest = {
        reason: approverForm.notes,
        action: stat,
        customerType: "REGULAR",
        pageId: pageId,
        keyAuth: keyAuth,
    }

    await executeApprover(amlActionRequest).then(res => {
      onSubmit(res.data)
    }).catch( e=> {
      let resData = {
        code: "FAILED",
        message: "Try again later"
      }
      onSubmit(resData)
    })   
  }

  const approverSubmit = (e) => {
      e.preventDefault()
      executeApproverAction(e);
  }

  return (
    <Modal
        isOpen={isOpen}
        toggle={handleCloseModalApprover}
        size="lg"
        centered
        onClosed={() => {setIsOpenModalApprover(false)}}>
            
      <ModalHeader
        toggle={handleCloseModalApprover}
        style={{ borderBottom: 'none' }}>
        <span style={{fontSize:'1.4em'}}>{getTitle(stat)}</span>
      </ModalHeader>

      <ModalBody >
        <Container>
          <Form onSubmit={approverSubmit}>
            <FormGroup>
              <Label for="notes">
                Catatan
              </Label>
              <Input
                id="notes"
                height='200px'
                name="notes"
                style={{height: '150px'}}
                placeholder="Masukkan catatan"
                type="textarea"
                onChange={handleNoteChange}
                required
              />
            </FormGroup>
            
            <div className="text-center mt-4">
              <Button type="submit" color="primary">Submit</Button>
            </div>
          </Form>
        </Container>
      </ModalBody>
      <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
    </Modal>
  )

}

export default AmlModalApprover;