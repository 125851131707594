// import { Dropdown } from "app/component/dropdown/dropdown";
import React, { useState } from "react"
import { Dropdown, DropdownButton } from 'react-bootstrap';

const AmlDropdown = (props) => {
    
    const [selectedItem, setSelectedItem] = useState('Search by')

    const onSelect = (eventKey, event) => {
        let clickedItem = props.items.filter(e => e.eventKey === eventKey)[0]
        setSelectedItem(clickedItem.name)
        props.onSelect(eventKey)
        
    }
    
    return (
        <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={selectedItem}
            onSelect={onSelect} >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            {
                props.items.map(e => <Dropdown.Item key={e.eventKey} eventKey={e.eventKey}>{e.name}</Dropdown.Item>)
            }
          </DropdownButton>
    )

}

export default AmlDropdown;