import React, {useEffect, useState} from "react"

const AmlTab = (props) => {
    const [isOpen, setOpen] = useState(props.isOpen)
    // useEffect(()=> {
        
    // }, [props.isOpen])

    function isActiveRender() {
        return props.isOpen ? 'nav-link active' : 'nav-link'
    }

    const onClick = (e) => {
        props.onClick(props.id)
    }

    return (
        <li className='nav-item'>
            <a key={props.id} className={isActiveRender()} style={{cursor: 'pointer'}} onClick={(e) => onClick(e)}>
                {props.title}</a>
        </li>
    )

}

export default AmlTab