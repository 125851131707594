import React from "react";

const AmlButtonMaker = ({onClick}) => {

    const onClickButtonMaker = (e) => onClick(e)

    return(
        <>
            <a
                className="btn btn-danger text-white text-end"
                onClick={onClickButtonMaker}
                style={{ background: '#CC7633' }}>
                Tinjau Pengajuan
            </a>
        </>
    )

}

export default AmlButtonMaker;