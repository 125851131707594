import { BASE_URL } from "app/utils/constant";
import Axios from "axios"

export interface AmlActionRequest {
    docRef?: string;
    doc?: File;
    ref: string;
    notes: string;
    action: string;
    customerType: string;
}

export interface AmlApproverRequest {
    pageId: string;
    keyAuth: string;
    action: string;
    reason: string;
    customerType: string;
}

export const getAmlApprovalHistories = async (id) => {
    return Axios.post(`${BASE_URL.HOTSREGIS}api/v1/aml/list/${id}`)
}

export const executeAmlAction = async (request: AmlActionRequest) => {
    let accessToken = localStorage.getItem('access_token')
    let form = new FormData();
    form.append("docRef",request.docRef)
    form.append("doc",request.doc)
    form.append("ref",request.ref)
    form.append("notes",request.notes)
    form.append("action",request.action)
    form.append("customerType",request.customerType)

    return Axios.post(
        `${BASE_URL.HOTSREGIS}api/amlevaluation/execute`,
        form,
        { 
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        },})
}

export const executeApprover = async (request: AmlApproverRequest) => {

    return Axios.post(
        `${BASE_URL.HOTSREGIS}api/amlevaluation/execute/approver`,
        request,
        { headers: {'Content-Type': 'application/json',},})
}

export const downloadAmlFile = async (path) => {
    return Axios.get(
        `${BASE_URL.HOTSREGIS}api/v1/aml/document/${path}`,
        {responseType: 'blob', withCredentials: true}
        
    )
}