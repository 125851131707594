import '../styles/aml-approver.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastOptions } from 'react-toastify';
import { Accordion } from 'react-bootstrap';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';
import AmlApprovalTable from '../../aml/table/aml-approval-table';
import AmlOccupationMapper from './aml-occupation-mapper';
import MiraeLogo from '../asset/mirae-logo';
import AmlApproverButton from './aml-approver-button';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import AmlModalApprover from 'app/entities/aml/modal/aml-modal-approver';
import { getAmlApprovalHistories } from 'app/entities/aml/api/aml-api';
import { getPageDetail } from '../api/aml-approver-api';


export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
  match?: any;
}

const initApprovalList = [
  {
    createdDate: null,
    username: null,
    status: null,
    approval: null,
    notes: null
  }
]

const toastConfig: ToastOptions = {
  position: 'top-left',
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

interface AmlProgress {
  code: string;
  name: string;
}

const progressList: AmlProgress[] = [
  {
    code: "MAKER_ON_PROGRESS",
    name: "Menunggu Review"
  },
  {
    code: "REVIEWER_ON_PROGRESS",
    name: "Menunggu Persetujuan"
  },
  {
    code: "APPROVER_ON_PROGRESS",
    name: "Menunggu Persetujuan Final"
  }
]

export const FieldWrapper = (props) => {
  const {...otherProps} = props;
  return (
    <div className={`col-xs-12 col-md-6 ${props.additionalClass}`}>
      {props.children}
    </div>
  )
}

export const FieldElement = ({title, value}) => {
  return (
    <div className='d-flex py-2'>
      <div className="d-flex flex-grow-1 align-items-center pe-2" style={{maxWidth: '180px', minWidth: '180px'}}>{title}</div>
      <div className="d-flex flex-grow-1 align-items-center justify-content-end" >
        <input
          className="py-2 rounded"
          style={{width: '100%'}}
          type="text"
          disabled={true}
          value={value}
        />
      </div>
    </div>
  )
}

export const AmlApproverDetailPage = (props: IHeaderProps) => {
  let history = useHistory();
  let params = useParams();
  const [loadingList, setLoadingList] = useState(false);

  const [index, setIndex] = useState(0);
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(false);
  const [detail, setDetail] = useState<any>([]);
  const [listApproval, setListApproval] = useState<any>(initApprovalList);
  const [urlImageKTP, setUrlImageKTP] = useState<any>(null);
  const [urlImageNPWP, setUrlImageNPWP] = useState<any>(null);
  const [urlImageSelfie, setUrlImageSelfie] = useState<any>(null);
  const [urlImageTTD, setUrlImageTTD] = useState<any>(null);
  const [sort, setSort] = useState<any>('createdDate');
  const [ascending, setAscending] = useState<boolean>(false);
  const [progress, setProgress] = useState<AmlProgress>(progressList[0]);
  const [showModalApprover, setShowModalApprover] = useState<boolean>(false);
  const [modalApproverStat, setModalApproverStat] = useState<string>('APPROVE');
  const [selectedImg, setSelectedImg] = useState<string>('')
  const [customerId, setCustomerId] = useState<string>('')

  useEffect(() => {
  }, [listApproval, progress]) 

  useEffect(() => {

    let isMounted = true;
    if(isMounted) {
      getPageDetail(params.key).then((res) => {
        
        let customerId = res.data.data.customerId
        
          setCustomerId(customerId)
          getAmlApprovalHistories(customerId).then((res) => {

            let username = res.data[0].username
            let approvalResult = res.data[0].approvalHistoryDetails;
            let currentProgress = res.data[0].amlCurrentProcess;
            let approvalState = approvalResult.map(e => {
              return {
                createdDate: e.createdDate,
                username: username,
                status: e.progress,
                action: e.action,
                approval: e.amlActor.empNm,
                amlFile: e.amlFile,
                notes: e.notes
              }
            })
            
            setListApproval(approvalState);
            let current = progressList.filter(e => e.code === currentProgress)[0]
            setProgress(current);
            
              
          })
          .catch((err) => {
            history.replace("/not-found")
          });
      
          getDetailAnnouncement(customerId).then(async (res) => {
            
            setLoadingList(true);
            setDetail(res.data.data);
            if (res.data.data.ktpImage != '-') {
              setUrlImageKTP(
                'data:image/png;base64,' + (await getImage(res.data.data.ktpFile))
              );
            }
            if (res.data.data.npwpImage != '-') {
              setUrlImageNPWP(
                'data:image/png;base64,' + (await getImage(res.data.data.npwpFile))
              );
            }
            if (res.data.data.selfieImage != '-') {
              setUrlImageSelfie(
                'data:image/png;base64,' +
                  (await getImage(res.data.data.selfieFile))
              );
            }
            if (res.data.data.ttdImage != '-') {
              setUrlImageTTD(
                'data:image/png;base64,' +
                  (await getImage(res.data.data.signatureUrl))
              );
            }
            
            setLoadingList(false);
            
          })
          .catch((err) => {
            setLoadingList(false);
            history.replace("/not-found")
          });
        
      }).catch(err => {
        history.replace("/not-found")
      })
    }
    return () => { isMounted = false };
    
  }, []);

  const getDetailAnnouncement = async (id) => {
    let languageValue = 'ID';
    return axios.get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${id}?language=${languageValue}`
      )
  };

  const handleSelect = (selectedIndex, img) => {
    setIndex(selectedIndex);
    setSelectedImg(img)
    
  };

  const handleCloseModalApprover = () => setShowModalApprover(false)

  function getImage(imageId: string) {
    return axios
      .get(`${BASE_URL.HOTSREGIS}api/backOffice/load-image/${imageId}`)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        
        setLoadingList(false);
      });
  }

  const getApprovalHistory = async (id) => {
    await getAmlApprovalHistories(id).then((res) => {

        let username = res.data[0].username
        let approvalResult = res.data[0].approvalHistoryDetails;
        let currentProgress = res.data[0].amlCurrentProcess;
        let approvalState = approvalResult.map(e => {
          return {
            createdDate: e.createdDate,
            username: username,
            status: e.progress,
            action: e.action,
            approval: e.amlActor.empNm,
            amlFile: e.amlFile,
            notes: e.notes
          }
        })
        
        setListApproval(approvalState);
        let current = progressList.filter(e => e.code === currentProgress)[0]
        setProgress(current);
      })
      .catch((err) => {
        
      });
  };
  
  const handleClose = () => {
    setShowModalPreviewImage(false);
  };

  function handleRejectList(item: any) {
    var rejectReason = '';

    if (item?.listReject != null) {
      rejectReason = item?.listReject[0]?.rejectReasonDetail;
    } else {
    }
    return rejectReason;
  }

  const afterSubmit = (data) => {
    if (data.code == 'SUCCESS') {
      toast.success('Success Approve', toastConfig);
    } else {
      toast.error('Failed Approve: ' + data.message, toastConfig);
    }
      setShowModalApprover(false)
      getApprovalHistory(props.match.params.id)
  }

  const handleButtonReviewer = (action) => {
    setShowModalApprover(true);
    setModalApproverStat(action)
  }
  
  return !loadingList ? (
    <>
      <div className="container" style={{ background: '#fff', height: '100%', paddingBottom: '128px' }}>
      <div className="row mt-4">
        <div className='col-xs-12'>
        <MiraeLogo />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xs-12">
          <h1 className="mb-0 font-size-32">{progress?.name}</h1>
        </div>
      </div>
      <div className="row mt-3">
        <div className='col-xs-12'>
          <p className="" style={{ fontSize: '18px' }}>
            {' '}
            {detail?.name}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 py-2">
          <Accordion defaultActiveKey={['1', '7']} alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Dokumen</Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                <div className="row align-items-center mb-3">
                  <div className="col-6">KTP</div>
                  <div className="col-6">NPWP</div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <img
                      className="image-button w-50"
                      src={urlImageKTP}
                      alt=""
                      onClick={(data) => {
                        setShowModalPreviewImage(true);
                        handleSelect(0, urlImageKTP);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <img
                      className="image-button w-50"
                      src={urlImageNPWP}
                      alt=""
                      onClick={(data) => {
                        setShowModalPreviewImage(true);
                        handleSelect(1, urlImageNPWP);
                      }}
                    />
                  </div>
                </div>

                <div className="row align-items-center mb-3">
                  <div className="col-6">Selfie</div>
                  <div className="col-6">Tanda Tangan</div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <img
                      className="image-button w-50"
                      src={urlImageSelfie}
                      alt=""
                      onClick={(data) => {
                        setShowModalPreviewImage(true);
                        handleSelect(2, urlImageSelfie);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <img
                      className="image-button w-50"
                      src={urlImageTTD}
                      alt=""
                      onClick={(data) => {
                        setShowModalPreviewImage(true);
                        handleSelect(3, urlImageTTD);
                      }}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <h5>Score</h5>
              </Accordion.Header>
              <Accordion.Body className="" style={{ background: '#fff' }}>
                <div className="row align-items-center ">
                  <FieldWrapper >
                    <FieldElement title="AML Score" 
                      value={(Math.round(detail?.score * 100) / 100).toFixed(
                        2
                      )}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Risk Status" value={detail?.riskStatus}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Liveness Score" value={detail?.faceComparisonScore}/>
                  </FieldWrapper>
                  
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h5>Data Nasabah</h5>
              </Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                
                <div className="row align-items-center  mb-3">
                  <FieldWrapper >
                    <FieldElement title="User ID" value={detail?.username}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nomor Identitas" value={detail?.idNumber}/>
                  </FieldWrapper>
                 
                <FieldWrapper additionalClass=''>
                    <FieldElement title="Nama Lengkap (Sesuai KTP)" value={detail?.name}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement 
                      title="Phone Number" 
                      value={(detail?.dialCode + detail?.phoneNumber)
                        .toString()
                        .replace(/(?<=\d\d)\d(?=\d{2})/g, '*')}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Tempat & Tanggal Lahir" 
                      value={`${detail?.placeOfBirth}, ${formatDate(detail?.birthday, 'DD MMM YYYY')}`}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="NPWP" value={detail?.npwpId}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Email" 
                      value={detail?.email}/>
                  </FieldWrapper>
                
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Negara tempat lahir" value={detail?.countryOfBirth}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Agama" value={detail?.religionName}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nama Gadis Ibu Kandung" value={detail?.motherName}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Jenis Kelamin" value={detail?.genderName}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Status Rumah" value={detail?.ownershipOfHouseStatusName}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Status Pernikahan" value={detail?.maritalStatusName}/>
                  </FieldWrapper>
                 
                  {detail?.maritalStatusName?.toUpperCase() === 'MENIKAH' && (
                    <>
                      <FieldWrapper additionalClass=''>
                        <FieldElement title="Nama Pasangan" value={detail?.spouseName}/>
                      </FieldWrapper>
                    </>
                  )}
           
                  <div className="col-12 py-2">
                    <h5>Alamat Sesuai KTP</h5>
                  </div>
             
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Alamat" value={detail?.address}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Negara" value={detail?.country}/>
                  </FieldWrapper>
                 
                <FieldWrapper additionalClass=''>
                    <FieldElement title="Provinsi" value={detail?.province}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kab/Kota" value={detail?.city}/>
                  </FieldWrapper>
                
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kelurahan" value={detail?.village}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kecamatan" value={detail?.district}/>
                  </FieldWrapper>
              
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="RT/RW" value={detail?.detailRtRw}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kode Pos" value={detail?.postalCode}/>
                  </FieldWrapper>
                 
                  <div className="col-12 py-2">
                    <h5>Alamat Domisili</h5>
                  </div>
             
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Alamat" value={detail?.domicileAddress}/>
                  </FieldWrapper>
                
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Negara" value={detail?.domicileCountry}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Provinsi" value={detail?.domicileProvince}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kab/Kota" value={detail?.domicileCity}/>
                  </FieldWrapper>
                 
                <FieldWrapper additionalClass=''>
                    <FieldElement title="Kelurahan" value={detail?.domicileVillage}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kecamatan" value={detail?.domicilDistrict}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="RT/RW" value={detail?.domicileDetailRtRw}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kode Pos" value={detail?.domicilePostalCode}/>
                  </FieldWrapper>
                 
                  <div className="col-12 py-2">
                    <h5>Alamat Surat Menyurat</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Alamat Surat Menyurat" value={detail?.mailingAddressName}/>
                  </FieldWrapper>
                </div>
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h5>Data Bank</h5>
              </Accordion.Header>
              <Accordion.Body className="" style={{ background: '#fff' }}>
                <div className="row align-items-center mb-3">
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Bank RDN" value={detail?.bankRDNName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="No Rekening RDN" value={detail?.rdnAccount}/>
                  </FieldWrapper>
                  <div className="col-12 py-2">
                    <h5>Rekening Pencairan</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Bank" value={detail?.bankDestinationName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="No Rekening" value={detail?.accountBankNo}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nama Pemilik Rekening" value={detail?.accountBankName}/>
                  </FieldWrapper>
                  <div className="col-12 py-2">
                    <h5>Lain - lain</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nomor Rekening Sekarang" value={detail?.accountBankNoExisting}/>
                  </FieldWrapper>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h5>Data Pekerjaan</h5>
              </Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                {/* Checking Pekerjaan */}
                <AmlOccupationMapper occupationCode={detail?.occupation} detail={detail} /> 
                {/* Close Checking Pekerjaan */}
                <div className="row align-items-center mb-3">
                  <div className="col-12 py-2">
                    <h5>Pendapatan</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Pendapatan Kotor Per Tahun" value={detail?.salaryRangeName}/>
                  </FieldWrapper>
                  <div className="col-12 py-2">
                    <h5>Sumber Dana & Tujuan Investasi</h5>
                  </div>  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Sumber Dana" value={detail?.sourceOfIncomeName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Tujuan Investasi" value={detail?.investmentGoalName}/>
                  </FieldWrapper>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Approval History</Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                <div className="row align-items-center mb-3">
                  <AmlApprovalTable
                    listApproval={listApproval} 
                    sort={sort} 
                    ascending={ascending} 
                    setSort={setSort} 
                    setAscending={setAscending} 
                    formatDate={formatDate} 
                    handleRejectList={handleRejectList}
                    />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      
      {/* Modal */}
      <Modal
        isOpen={showModalPreviewImage}
        toggle={handleClose}
        centered
        size='xl'
        onClosed={() => {
          setShowModalPreviewImage(false);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question" className='m-auto'>
          <TransformWrapper centerZoomedOut={true}>
            <TransformComponent >
              <img style={{minHeight: '400px', objectFit: 'contain'}} width={'100%'} src={selectedImg} alt="test" />
            </TransformComponent>
          </TransformWrapper>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>

    {progress?.code === "APPROVER_ON_PROGRESS" && <AmlModalApprover
        keyAuth={params.key}
        pageId={params.id}
        stat={modalApproverStat}
        isOpen={showModalApprover} 
        handleCloseModalApprover={handleCloseModalApprover} 
        setIsOpenModalApprover={setShowModalApprover} 
        onSubmit={(data) => afterSubmit(data)}
      />}
    <AmlApproverButton onClick={action => handleButtonReviewer(action)}/>
    </>
    
  ) : (
    <>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
    </>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AmlApproverDetailPage);
