import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Document from './document';
import Sent from './sent';
import Inbox from './inbox';
import Approval from './approval';
import Acknowledge from './acknowledge/';
import Preview from './preview';
import Histori from './history/Histori';
import HistoriDetail from './history/HistoriDetail';
import NotFound from './document/NotFound';
import Search from './search/Search';
import { Annoucement } from './announcement/Announcement';
import AnnouncementCreate from './announcement/AnnouncementCreate';
import AnnouncementEdit from './announcement/AnnouncementEdit';
import AnnouncementView from './announcement/AnnouncementView';
import Notification from './notification/Notification';
import KaryawanAdd from './karyawan/Karyawan-add';
import Karyawan from './karyawan/Karyawan';
import KaryawanAddManual from './karyawan/Karyawan-add-manual';
import Group from './group/Group';
import GroupDetail from './group/GroupDetail';
import Clfpr from './clfpr/Clfpr';
import { AML } from './aml/aml';
import { AMLView } from './aml/amlView';
import { VerificationDetail } from './document/verification-detail';
import { Audit } from './audit/audit';
import { AuditView } from './audit/auditview';
import { Log } from './log/log';
import Activities from './activities/Activities';
import Summaries from './summary/Summaries';
import LogAccUpdate from './log/log-acc-update';
import LogAccUpdatePrev from './log/log-acc-update-preview';
import { Reports } from './reports/reports';
import { ReportsDetail } from './reports/report-detail';
import { DeleteAccounts } from './delete-account/delete-accounts';
import { UpgradeList } from './upgrade/upgradelist';
import { UpgradeDetail } from './upgrade/upgrade-detail';
import { SystemLog } from './system-log/system-log';
import { MasterData } from './master/master-data';
import { MasterDataView } from './master/master-data-view';
import { PartnerList } from './partner/VerificationPartnerList';
import { PartnerDetail } from './partner/VerificationPartnerDetail';
import { ReportLivin } from './report-livin/report-livin';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      {/* <ErrorBoundaryRoute path={`${match.url}verification/list`} component={Document} /> */}
      <PrivateRoute
        path={`${match.url}verification/list`}
        component={Document}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />
      {/* <ErrorBoundaryRoute
        path={`${match.url}verification/view/:title/:id`}
        component={VerificationDetail}
      /> */}
      <PrivateRoute
        path={`${match.url}verification/view/:title/:id`}
        component={VerificationDetail}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />
      {/* <ErrorBoundaryRoute
        path={`${match.url}verification/partner-list`}
        component={PartnerList}
      /> */}
      {/* <PrivateRoute
        path={`${match.url}verification/partner-list`}
        component={PartnerList}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC, 
          AUTHORITIES.AML, 
        ]}
      /> */}
      {/* <ErrorBoundaryRoute
        path={`${match.url}verification/partner-view/:title/:id`}
        component={PartnerDetail}
      /> */}
      {/* <PrivateRoute
        path={`${match.url}verification/partner-view/:title/:id`}
        component={PartnerDetail}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC, 
          AUTHORITIES.AML, 
        ]}
      /> */}

      {/* <ErrorBoundaryRoute
        path={`${match.url}upgrade/list`}
        component={UpgradeList}
      /> */}
      <PrivateRoute
        path={`${match.url}upgrade/list`}
        component={UpgradeList}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />
      {/* <ErrorBoundaryRoute
        path={`${match.url}upgrade/view/:title/:id`}
        component={UpgradeDetail}
      /> */}
      <PrivateRoute
        path={`${match.url}upgrade/view/:title/:id`}
        component={UpgradeDetail}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />

      <ErrorBoundaryRoute path={`${match.url}sent`} component={Sent} />
      <ErrorBoundaryRoute path={`${match.url}inbox`} component={Inbox} />
      <ErrorBoundaryRoute path={`${match.url}approval`} component={Approval} />
      <ErrorBoundaryRoute
        path={`${match.url}acknowledge`}
        component={Acknowledge}
      />
      <ErrorBoundaryRoute path={`${match.url}preview`} component={Preview} />
      {/* <ErrorBoundaryRoute
        path={`${match.url}admin-reports`}
        exact
        component={Histori}
      /> */}

      {/* <ErrorBoundaryRoute
        path={`${match.url}activities`}
        exact
        component={Activities}
      /> */}
      <PrivateRoute
        path={`${match.url}activities`}
        component={Activities}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />

      {/* <ErrorBoundaryRoute
        path={`${match.url}summaries`}
        exact
        component={Summaries}
      /> */}
      <PrivateRoute
        path={`${match.url}summaries`}
        component={Summaries}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />

      {/* <ErrorBoundaryRoute
        path={`${match.url}logs`}
        exact
        component={LogAccUpdate}
      /> */}
      <PrivateRoute
        path={`${match.url}logs`}
        component={LogAccUpdate}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />

      {/* <ErrorBoundaryRoute path={`${match.url}cl-fpr`} exact component={Clfpr} /> */}
      <PrivateRoute
        path={`${match.url}cl-fpr`}
        component={Clfpr}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
          AUTHORITIES.CS,
        ]}
      />

      {/* <ErrorBoundaryRoute path={`${match.url}aml`} exact component={AML} /> */}
      <PrivateRoute
        path={`${match.url}aml/list`}
        component={AML}
        exact={true}
        hasAnyAuthorities={[
          AUTHORITIES.AML,
        ]}
      />
      <PrivateRoute
        path={`${match.url}aml/view/:id`}
        component={AMLView}
        exact={true}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          AUTHORITIES.AML,
        ]}
      />

      {/* <ErrorBoundaryRoute
        path={`${match.url}master-data/list`}
        exact
        component={MasterData}
      /> */}
      <PrivateRoute
        path={`${match.url}master-data/list`}
        component={MasterData}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />
      {/* <ErrorBoundaryRoute
        path={`${match.url}master-data/view/:id`}
        exact
        component={MasterDataView}
      /> */}
      <PrivateRoute
        path={`${match.url}master-data/view/:id`}
        component={MasterDataView}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />

      <ErrorBoundaryRoute path={`${match.url}search`} component={Search} />
      {/* <ErrorBoundaryRoute
        path={`${match.url}reports`}
        exact
        component={Reports}
      /> */}
      <PrivateRoute
        path={`${match.url}reports`}
        component={Reports}
        hasAnyAuthorities={[
          AUTHORITIES.OE,
          // AUTHORITIES.INTERNAL_AUDIT, 
          AUTHORITIES.KYC,
          AUTHORITIES.AML,
          AUTHORITIES.CS,
          AUTHORITIES.ADMIN_OOA3
        ]}
      />
      {/* <ErrorBoundaryRoute
        exact
        path={`${match.url}reports/view/:id`}
        component={ReportsDetail}
      /> */}
      <PrivateRoute
        path={`${match.url}reports/view/:id`}
        component={ReportsDetail}
        hasAnyAuthorities={[
          AUTHORITIES.OE,
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          AUTHORITIES.AML,
          AUTHORITIES.CS,
          AUTHORITIES.ADMIN_OOA3]}
      />

      {/* <ErrorBoundaryRoute
        path={`${match.url}report-livin`}
        exact
        component={ReportLivin}
      /> */}
      {/* <PrivateRoute
        path={`${match.url}report-livin`}
        component={ReportLivin}
        hasAnyAuthorities={[AUTHORITIES.OE, AUTHORITIES.INTERNAL_AUDIT, AUTHORITIES.KYC, AUTHORITIES.AML, AUTHORITIES.CS, AUTHORITIES.ADMIN_OOA3]}
      /> */}

      <ErrorBoundaryRoute
        path={`${match.url}announcement/create`}
        component={AnnouncementCreate}
      />
      <ErrorBoundaryRoute
        path={`${match.url}announcement/edit/:id`}
        component={AnnouncementEdit}
      />

      {/* <ErrorBoundaryRoute path={`${match.url}audit/list`} component={Audit} /> */}
      <PrivateRoute
        path={`${match.url}audit/list`}
        component={Audit}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          // AUTHORITIES.KYC, 
          // AUTHORITIES.AML, 
        ]}
      />
      {/* <ErrorBoundaryRoute
        path={`${match.url}audit/view/:id`}
        component={AuditView}
      /> */}
      <PrivateRoute
        path={`${match.url}audit/view/:id`}
        component={AuditView}
        hasAnyAuthorities={[
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.KYC,
          // AUTHORITIES.AML, 
        ]}
      />
      {/* <ErrorBoundaryRoute
        path={`${match.url}system-log/list`}
        component={SystemLog}
      /> */}
      <PrivateRoute
        path={`${match.url}system-log/list`}
        component={SystemLog}
        hasAnyAuthorities={[
          // AUTHORITIES.INTERNAL_AUDIT, 
          // AUTHORITIES.KYC, 
          // AUTHORITIES.AML,
          AUTHORITIES.ADMIN_OOA3,
          AUTHORITIES.IT_SUPPORT
        ]}
      />

      <ErrorBoundaryRoute
        path={`${match.url}notification`}
        component={Notification}
      />
      <ErrorBoundaryRoute
        path={`${match.url}karyawan/add`}
        component={KaryawanAddManual}
      />
      <ErrorBoundaryRoute
        path={`${match.url}karyawan/upload`}
        component={KaryawanAdd}
      />
      <ErrorBoundaryRoute path={`${match.url}karyawan`} component={Karyawan} />
      <ErrorBoundaryRoute
        path={`${match.url}group/detail/:id`}
        component={GroupDetail}
      />
      <ErrorBoundaryRoute path={`${match.url}group`} component={Group} />
      <ErrorBoundaryRoute path={`${match.url}/404`} component={NotFound} />

      <PrivateRoute path={`${match.url}delete-account`} component={DeleteAccounts} hasAnyAuthorities={[AUTHORITIES.IT_SUPPORT]} />

      {/* <ErrorBoundaryRoute  path={`${match.url}/404`} component={NotFound}/> */}
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
