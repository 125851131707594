import * as React from "react"
import { SVGProps } from "react"

const SuccessIllustration = (props: SVGProps<SVGSVGElement>) => (
    <svg 
    width={32} 
    height={32}
    fill="#43936C"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM23.4142 13.4142C24.1953 12.6332 24.1953 11.3668 23.4142 10.5858C22.6332 9.80474 21.3668 9.80474 20.5858 10.5858L14 17.1716L11.4142 14.5858C10.6332 13.8047 9.36683 13.8047 8.58579 14.5858C7.80474 15.3668 7.80474 16.6332 8.58579 17.4142L12.5858 21.4142C13.3668 22.1953 14.6332 22.1953 15.4142 21.4142L23.4142 13.4142Z"/>
    </svg>
)

export default SuccessIllustration