import React, {useState} from "react";
import {
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Input,
    ModalFooter,
    Button,
    Label,
    Container,
  } from 'reactstrap';
import { executeAmlAction } from "../api/aml-api";
import Axios from "axios";


interface ReviewerForm {
    notes: string;
}

const initForm: ReviewerForm = {
    notes: null,
}

const   AmlModalReviewer = ({refId, stat, isOpen, handleCloseModalReviewer, setIsOpenModalReviewer, onSubmit}) => {
  const [reviewerForm, setReviewerForm] = useState<ReviewerForm>(initForm)

  const handleNoteChange = (e) => setReviewerForm({...reviewerForm, notes: e.target.value})

  const getTitle = (stat) => {
    return stat == "APPROVE" ? "Menyetujui Persetujuan" : stat == "REJECT" ? "Menolak Persetujuan" : "Merevisi Persetujuan"
  }

  const executeReviewerAction = async (e) => {
    let amlActionRequest = {
        ref: refId,
        notes: reviewerForm.notes,
        action: stat,
        customerType: "REGULAR",
    }

    function logoutFunction() {
      return Axios
        .post(`auth/logout`)
        .then(async (res) => { })
        .catch((err) => {
          console.log(err);
        });
    }

    await executeAmlAction(amlActionRequest).then(res => {
      onSubmit(res.data)
    }).catch( err=> {
        let resData = {
        code: "FAILED",
        message: "Try again later"
      }
      console.log('hello, ',err)
      if(err.data.error === "invalid_token"){
        logoutFunction()
      }
      
      onSubmit(resData)
    })   
  }

  const reviewerSubmit = (e) => {
      e.preventDefault()
      executeReviewerAction(e);
  }

  return (
    <Modal
        isOpen={isOpen}
        toggle={handleCloseModalReviewer}
        size="lg"
        centered
        onClosed={() => {setIsOpenModalReviewer(false)}}>
            
      <ModalHeader
        toggle={handleCloseModalReviewer}
        style={{ borderBottom: 'none' }}>
        <h3>{getTitle(stat)}</h3>
      </ModalHeader>

      <ModalBody >
        <Container>
          <Form onSubmit={reviewerSubmit}>
            <FormGroup>
              <Label for="notes">
                Catatan
              </Label>
              <Input
                id="notes"
                name="notes"
                placeholder="Masukkan catatan"
                type="textarea"
                onChange={handleNoteChange}
                required
              />
            </FormGroup>
            
            <div className="text-center mt-5">
              <Button type="submit" color="primary">Submit</Button>
            </div>
          </Form>
        </Container>
      </ModalBody>
      <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
    </Modal>
  )

}

export default AmlModalReviewer;