import { BASE_URL } from "app/utils/constant"
import Axios from "axios"


export interface AmlApproverValidationRequest {
    id: string,
    otp: string
}

export const getPage = async (pageId: string) => {
    return Axios.get(
        `${BASE_URL.HOTSREGIS}api/v1/aml/approval/page/${pageId}`
    )
}

export const getPageDetail = async (keyAuth: string) => {
    return Axios.post(
        `${BASE_URL.HOTSREGIS}api/v1/aml/approval/page/detail/${keyAuth}`
    )
}

export const validateOtp = async (request: AmlApproverValidationRequest) => {
    return Axios.post(
        `${BASE_URL.HOTSREGIS}api/v1/aml/approval/page/validate`,
        request,
    )
}