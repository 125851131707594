import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const DeleteConfirmationModal = ({
  showModal,
  handleClose,
  selectedItem,
  loadingDelete,
  deleteFile,
  setShowModal,
  setItemSelected,
}) => {
  const handleDelete = () => {
    deleteFile(selectedItem?.id);
  };

  const handleCancel = () => {
    setShowModal(false);
    setItemSelected(null);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      size="lg"
      centered
      onClosed={handleCancel}
    >
      <ModalHeader toggle={handleClose} style={{ borderBottom: 'none' }} />
      <ModalBody id="documenttestApp.document.delete.question">
        <h3 className="text-center">
          Are you sure you want to delete{' '}
          <span className="font-weight-bold">"{selectedItem?.title}"</span>{' '}
          announcement?
        </h3>
        <div className="text-center mt-5">
          <button
            className="btn btn-success mx-2 px-4"
            onClick={handleDelete}
            disabled={loadingDelete}
          >
            {loadingDelete && (
              <Spinner color="#fff" size="sm" className="mr-1" />
            )}{' '}
            Yes
          </button>
          <button
            disabled={loadingDelete}
            className="btn btn-secondary mx-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </ModalBody>
      <ModalFooter style={{ borderTop: 'none' }} />
    </Modal>
  );
};

export default DeleteConfirmationModal;