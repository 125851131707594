import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';

import '../../../../../../../scss/app.min.scss';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { logout } from 'app/shared/reducers/authentication';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import axios from 'axios';
import { menuRolesAndPermissionLists } from 'app/config/menus';

export interface IDocumentProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{ url: string }> { }

const SideBar = (props: IDocumentProps, { isOpen, toggle }) => {
  const { height, width } = useWindowDimensions();
  const [openDropdown, setOpenDropdown] = useState<any>(null);
  const [showKaryawan, setShowKaryawan] = useState<any>(false);
  const [canUpload, setCanUpload] = useState<any>(false);
  const [canCreate, setCanCreate] = useState<any>(false);
  const [isKYC, setIsKYC] = useState<any>(false);
  const [isOE, setIsOE] = useState<any>(false);
  const [isAML, setIsAML] = useState<any>(false);
  const [isCS, setIsCS] = useState<any>(false);
  const [isAudit, setIsAudit] = useState<any>(false);
  const [isHC, setIsHC] = useState<any>(false);
  const [isDMS, setIsDMS] = useState<any>(false);
  const [isAdminOOA3, setIsAdminOOA3] = useState<any>(false);
  const [isITSupport, setITSupport] = useState<any>(false);

  const [authorities, setAuthorities] = useState<string[]>([]);
  let history = useHistory();

  useEffect(() => {
    // isAdminHC();
    // isAdminKYC();
    // isAdminDMS();
    // isOEEmployee();
    // isAdminAML();
    // isAdminCS();
    // iscanCreate();
    // isAdminAudit();
    // checkAdminOOA3();
    // checkITSupport();
    getDataUser();
  }, []);

  async function getDataUser() {
    try {
      const res = await axios
        .get(`services/uaadocservice/api/account`);

      setAuthorities(res.data.authorities)
    } catch (err) {
      console.error(err);
    }
  }

  const iscanCreate = () => {
    Axios.get(`/services/uaadocservice/api/is-user-may-create`)
      .then(async (res) => {
        if (res.data) {
          setCanCreate(true);
        } else {
          setCanCreate(false);
        }
      })
      .catch((err) => {
        setCanCreate(false);
      });
  };

  const isAdminHC = () => {
    Axios.get(`/services/uaadocservice/api/is-admin-hc`)
      .then(async (res) => {
        if (res.data) {
          setIsHC(true);
        } else {
          setIsHC(false);
        }
      })
      .catch((err) => {
        setIsHC(false);
      });
  };

  const isAdminDMS = () => {
    Axios.get(`/services/uaadocservice/api/is-admin-dms`)
      .then(async (res) => {
        if (res.data) {
          setIsDMS(true);
        } else {
          setIsDMS(false);
        }
      })
      .catch((err) => {
        setIsDMS(false);
      });
  };

  const isOEEmployee = () => {
    Axios.get(`/services/uaadocservice/api/is-oe-employee`)
      .then(async (res) => {
        if (res.data) {
          setIsOE(true);
        } else {
          setIsOE(false);
        }
      })
      .catch((err) => {
        setIsOE(false);
      });
  };

  const isAdminCS = () => {
    Axios.get(
      `/services/uaadocservice/api/is-user-in-role?roleName=ROLE_ADMIN_OE`
    )
      .then(async (res) => {
        if (res.data) {
          setIsCS(true);
        } else {
          setIsCS(false);
        }
      })
      .catch((err) => {
        setIsCS(false);
      });
  };

  const isAdminAML = () => {
    Axios.get(`/services/uaadocservice/api/is-aml-employee`)
      .then(async (res) => {
        if (res.data) {
          setIsAML(true);
        } else {
          setIsAML(false);
        }
      })
      .catch((err) => {
        setIsAML(false);
      });
  };

  const isAdminKYC = () => {
    Axios.get(`/services/uaadocservice/api/is-admin-kyc`)
      .then(async (res) => {
        if (res.data) {
          setIsKYC(true);
        } else {
          setIsKYC(false);
        }
      })
      .catch((err) => {
        setIsKYC(false);
      });
  };

  const isAdminAudit = () => {
    Axios.get(
      `/services/uaadocservice/api/is-user-in-role?roleName=ROLE_INTERNAL_AUDIT`
    )
      .then(async (res) => {
        if (res.data) {
          setIsAudit(true);
        } else {
          setIsAudit(false);
        }
      })
      .catch((err) => {
        setIsAudit(false);
      });
  };

  const checkAdminOOA3 = () => {
    Axios.get(
      `/services/uaadocservice/api/is-user-in-role?roleName=ROLE_ADMIN_OOA3`
    )
      .then(async (res) => {
        if (res.data) {
          setIsAdminOOA3(true);
        } else {
          setIsAdminOOA3(false);
        }
      })
      .catch((err) => {
        setIsAdminOOA3(false);
      });
  };

  const checkITSupport = () => {
    Axios.get(
      `/services/uaadocservice/api/is-user-in-role?roleName=ROLE_IT_SUPPORT`
    )
      .then(async (res) => {
        if (res.data) {
          setITSupport(true);
        } else {
          setITSupport(false);
        }
      })
      .catch((err) => {
        setITSupport(false);
      });
  };

  function logoutFunction() {
    return axios
      .post(`auth/logout`)
      .then(async (res) => { })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleLogout = async () => {
    await logoutFunction();
    // await delayTime(5000);
    history.replace('/');
    await toast.success('Berhasil Logout', {
      position: 'top-left',
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleDynamicMenu = () => {
    const sideitems = [];

    menuRolesAndPermissionLists.filter((menu) => {
      return menu.roles.some(role => authorities.includes((role))) && menu
    }).forEach((filteredMenu, index) => {
      sideitems.push(
        <li key={index}>
          <a href={filteredMenu.path} className=" waves-effect">
            <i className={filteredMenu.icon}></i>
            <span>{filteredMenu.name}</span>
          </a>
        </li>
      );
    })

    return sideitems;
  };

  return (
    <div
      className={classNames('sidebar', { 'is-open': isOpen })}
      style={{ display: 'block' }}
    >
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: '#fff' }}>
          {/* &times; */}
        </span>
      </div>
      <div
        className="vertical-menu"
        id="sidebar-menu"
        style={isOpen ? { display: 'block' } : {}}
      >
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {handleDynamicMenu()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const submenus = [
  [
    {
      title: 'Menu 1',
      target: 'Menu-1',
    },
    {
      title: 'Menu 2',
      target: 'Menu-2',
    },
    {
      title: 'Menu 3',
      target: 'Menu-3',
    },
  ],
  [
    {
      title: 'Setting 1',
      target: 'Setting-1',
    },
    {
      title: 'Setting 2',
      target: 'Setting-2',
    },
    {
      title: 'Setting 3',
      target: 'Setting-3',
    },
  ],
];

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
});

const mapDispatchToProps = {
  // getSignature,
  logout,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
// export default SideBar;
