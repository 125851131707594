import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument } from '../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SignaturePad from 'react-signature-canvas'
import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { Document, pdfjs } from 'react-pdf';
import {createBrowserHistory} from 'history';
import { send } from 'process';
import { attempt, result } from 'lodash';
import { toast } from 'react-toastify';
import { getPreviewHTML } from 'app/utils/preview-pdf';
import './preview-style.scss'


const history = createBrowserHistory({ forceRefresh: true });

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }


export interface Props {
  getDocumentDetail: any,
  match: any,
  viewPDF: any,
  detaildocument: any,
  signDocument: any,
  approveDocument: any,
  blobPreview: any,
  filePDFSign: any,
  rejectDocument: any,
  PreviewPDFComponent: any,
  history: any,
  location: any
}

export const PreviewNewBackend: React.FC<Props> = (props) => {
  const [urlBlob, setUrlBlob] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [htmlTemp, setHtmlTemp] = useState<any>(null)
  const [fileNumberTemp, setFileNumberTemp] = useState<any>('')
  const [listFile, setListFile] = useState([])
  const [listLink, setListLink] = useState([])
  const [showModal, setShowModal] = useState(false)

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  const dataURLtoFile = (dataurl, filename) => {
    // console.log(dataurl?.split(','))
    var arr = dataurl?.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  const handleClose = () => {
    setShowModal(false)
  }




  const newSubmitDocument = async () => { 
  
    await setLoading(true)
    const formData = new FormData();
    formData.append("data", props.location.state.data);
    console.log(listFile)
    listFile.map((item, index) => {
      console.log(item.file)
      
      if(!item.fileAttachmentId){
        formData.append('fileAttachment', dataURLtoFile(item.file, item.fileName))
      }
    })
    
    listLink.map((data, index) => {
      formData.append("fileAttachmentLinks", data.value); 
    })
   
    axios.put('/services/documentservice/api/dms/send-first-step', formData).then(res => {
      console.log(res)
      const formDataSecondStep = new FormData();
      formDataSecondStep.append("id", res.data.data.id);
      formDataSecondStep.append("fileNumberTemp", fileNumberTemp);
      formDataSecondStep.append('data', props.location.state.data)
      formDataSecondStep.append("html", htmlTemp);
      // formDataSecondStep.append("fileAttachment", data);
      axios.put('/services/documentservice/api/dms/send-second-step-new', formDataSecondStep).then(res => { 
        props.history.push('/')
        setLoading(false)
        toast.success('Berhasil Membuat Dokumen', {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }).catch(async (err) => {
        setLoading(false)
      
        await toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        await delayTime(3000)
          if(err.response.data.message == "error.errordocservice.message.error27" || err.response.data.message == "error.errordocservice.message.error28") {
            history.replace('/draft')
          }
      })
    }).catch(err => {
      setLoading(false)
      console.log('pesan error', err.response.data.message)
      console.log('type', err.response.data.message)
      toast.error(translate(`${err.response.data.message}`), {
        toastId: err.response.data.message,
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      // if(err.response.data.message == '')
    })
  }


  const getDataPreview = async () => {
    console.log('data yang dikirim', props.location.state.data)
   axios.post('/services/documentservice/api/documents/get-data-for-preview', JSON.parse(props.location.state.data)).then(res => {
     console.log('ini response api', res)
     setFileNumberTemp(res.data.data.fileNumber)
     getPreviewPDF(res.data.data, res.data.data.fileNumber)
   }).catch(err => {
    toast.error(translate(`${err.response.data.message}`));
   })
  }


  const getPreviewPDF = (data, fileNumber) => {
    let html = getPreviewHTML(data)
    console.log('html', html)
    setHtmlTemp(html)
    converHTMLtoPDF(html, fileNumber)
  }

  const converHTMLtoPDF = (html, fileNumber) => {
    const formData = new FormData();
    formData.append("html", html);
    formData.append('fileNumber', fileNumber)
    formData.append('data', props.location.state.data)
    axios.post('/services/documentservice/api/dms/generate-pdf',formData, {responseType:'blob'}).then(res => {
      // window.open(URL.createObjectURL(res.data));

      setUrlBlob(URL.createObjectURL(res.data))
      // var url = new Blob([res.data], { type: 'application/pdf' });
      // var a = document.createElement('a')
      // a.href = url
      // a.download = fileName
      // a.click()
      // a.remove()
    })
  }

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
 }
    


  useEffect(() => {
   
    let hasil = getDataPreview()
    console.log('hasil dari be', hasil)
    console.log('hasil dari props', JSON.parse(props.location.state?.file))
    setListFile(JSON.parse(props.location.state?.file))
    if(props.location.state.link) {
      setListLink(JSON.parse(props.location.state?.link))
    }
  }, []);


  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  


  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl)
  }

  const goToBack = () => {
    props.history.push('/draft/create/edit', {file: listFile, link: listLink, data: props.location.state.data})
  }

  return (
    <div className="container">
    <Row>
      <Col>
        <h2>Draft</h2>
        <p>File Preview Draft</p>
      </Col>
      </Row>
      {urlBlob ?   <iframe width="100%" src={urlBlob} style={{width: '100%', height: '1000px'}} /> : <div className="shine photo"></div>}

      <div className="bg-white rounded shadow my-4 px-4 py-4"> 
                <h5>
                  Attachment
                </h5>

                {
                  listFile.length > 0 ? listFile.map((item, index) => {
                    return ( <div onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                      <div >
                            <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{background: '#F5F5F5', color: '#3267E3', alignContent: 'c'}} key={`file-${index}`}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <span>
                                    {item.fileName} ( {bytesToSize(item.fileSize)} )
                                </span>
                               
                              </div>
                            </div>
                      </div>
                    </div>
                    )
                  }) : <>

                  </>
                }

                
              </div>

              
      <div className="bg-white rounded shadow my-4 px-4 py-4"> 
                <h5>
                  Link
                </h5>

                {
                  listLink.length > 0 ? listLink.map((item, index) => {
                    return ( <div onClick={(e) => openInNewTab(item.value)}>
                      <div >
                            <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{background: '#F5F5F5', color: '#3267E3', alignContent: 'c'}} key={`file-${index}`}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <span>
                                    {item.value}
                                </span>
                               
                              </div>
                            </div>
                      </div>
                    </div>
                    )
                  }) : <>

                  </>
                }

                
              </div>
      
        {/* <div className="bg-white rounded shadow my-4 px-4 py-4"> 
                <h5>
                  Link
                </h5>

             
        </div> */}

            <Modal isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => {
              setShowModal(false)
            }}>
              <ModalHeader toggle={handleClose} style={{borderBottom: 'none'}}>
              </ModalHeader>
              <ModalBody id="documenttestApp.document.delete.question">
                  <h3 className="text-center">
                  Are you sure to send document with number {fileNumberTemp} ?
                  </h3>
                  <div className="text-center mt-5">
                    <button className="btn btn-success mx-2 px-4" onClick={() => newSubmitDocument()}>
                      {loading ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
                    </button >
                    <button disabled={loading} className="btn btn-secondary mx-2" onClick={() => {
                        setShowModal(false)
                    }}>
                                Cancel
                    </button >
                  </div>
              </ModalBody>
              <ModalFooter style={{borderTop: 'none'}}>

              </ModalFooter>
            </Modal>
      <Row className='mb-4 mt-4'>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
          </Col>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
            {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button>
              <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className=
              'mr-1'/>Reject</button>
              <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button> */}
            {/* <button className='btn btn-secondary px-3 mr-2' onClick={() => props.history.goBack()} disabled={loading}> Back</button> */}

            <Button onClick={() => goToBack() } className='btn btn-secondary px-3 mr-2' disabled={loading}> Back</Button>
            {/* <Button onClick={() => props.history.goBack() } className='btn btn-secondary px-3 mr-2' disabled={loading}> Back</Button> */}
            <button className='btn btn-primary px-3' onClick={() => setShowModal(true)} disabled={loading}>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
              Send </button>
            
            {/* <button className='btn btn-primary px-3' onClick={() => sendDocument()} disabled={loading}>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
              Send2</button> */}

{/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button> */}
          </Col>
        </Row>
      </div>
  )

};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewNewBackend);
