import './header.scss';

import '../../../../../../../scss/app.min.scss'

// import avatar1 from '../../../../../../../images/users/avatar-5.jpg';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { useHistory } from "react-router-dom";
import LoadingBar from 'react-redux-loading-bar';

import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { logout } from 'app/shared/reducers/authentication';
import HambIcon from 'app/component/bottomnav/HambIcon';
import axios from 'axios'

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any, 
  location?: Location,
  openMenu?: () => void,
  history?:any,
}

interface Location {
  state?:DataState
}

interface DataState {
  search?:any
}

const HeaderMobileNew = (props: IHeaderProps) => {
  let history: any = useHistory();
  

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

    useEffect(() => {
        // if(history.location.pathname == '')
    }, []);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header" className="pb-3" style={{ backgroundColor: '#F5F5F5 !important'}}>
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <header id="page-topbar">
        <div className="navbar-header px-2" style={{ backgroundColor: '#F5F5F5 !important' }}>

        <div className="d-flex" style={{ flexGrow: 1 }} onClick={() => props.openMenu()}>
            <HambIcon />
          </div>
        
          <div className="d-flex" style={{ flexGrow: 10 }}>
          <div className="app-search w-100">
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  logout
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobileNew)

