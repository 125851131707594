import React from "react"
import formatDate from 'app/utils/utils';
import { FieldElement, FieldWrapper } from "./aml-approver-detail-page";


const AmlOccupationMapper = ({occupationCode, detail}) => {
  
    let layoutOccupation;

    switch (occupationCode) {
      case '05,4':
        //HOUSEWIFE
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
                <FieldWrapper additionalClass=''>
                <FieldElement title="Pekerjaan" value={detail?.occupationName}/>
                </FieldWrapper>
              {/* <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div> */}
            </div>

            <div className="row align-items-center mb-3">
              <FieldWrapper additionalClass=''>
                  <FieldElement title="Nama Pasangan" value={detail?.spouseName}/>
                </FieldWrapper>
              {/* <div className="col-3">Nama Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.spouseName}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                    <FieldElement title="Tanggal Lahir" value={formatDate(detail?.dateOfBirthSpouse, 'DD MMM YYYY')}/>
                  </FieldWrapper>
              {/* <div className="col-3">Tanggal Lahir Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={formatDate(detail?.dateOfBirthSpouse, 'DD MMM YYYY')}
                />
              </div> */}
            </div>
          </>
        );
        break;
      case '01,6':
        //STUDENT
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <FieldWrapper additionalClass=''>
                <FieldElement title="Pekerjaan" value={detail?.occupationName}/>
              </FieldWrapper>
              {/* <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Nama Orang Tua" value={detail?.parentName}/>
              </FieldWrapper>
              {/* <div className="col-3">Nama Orang Tua</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.parentName}
                />
              </div> */}
            </div>

            <div className="row align-items-center mb-3">
              <FieldWrapper additionalClass=''>
                <FieldElement title="Tanggal Lahir Orang Tua" value={formatDate(detail?.dateOfBirthParent)}/>
              </FieldWrapper>
              {/* <div className="col-3">Tanggal Lahir Orang Tua</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={formatDate(detail?.dateOfBirthParent, 'DD MMM YYYY')}
                />
              </div> */}
            </div>
          </>
        );
        break;
      default:
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <FieldWrapper additionalClass=''>
                <FieldElement title="Pekerjaan" value={detail?.occupationName}/>
              </FieldWrapper>
              {/* <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Nama Perusahaan / Tempat Bekerja" value={detail?.companyName}/>
              </FieldWrapper>
              {/* <div className="col-3">Nama Perusahaan / Tempat Bekerja</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyName}
                />
              </div> */}
            
              <FieldWrapper additionalClass=''>
                <FieldElement title="Bidang Usaha" value={detail?.companyTypeName}/>
              </FieldWrapper>
              {/* <div className="col-3">Bidang Usaha</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyTypeName}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Jabatan" value={detail?.positionName}/>
              </FieldWrapper>
              {/* <div className="col-3">Jabatan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.positionName}
                />
              </div> */}
            
              <div className="col-12">
                <h5>Alamat Perusahaan Tempat Bekerja</h5>
              </div>
            
              <FieldWrapper additionalClass=''>
                <FieldElement title="Alamat" value={detail?.companyAddress}/>
              </FieldWrapper>
              {/* <div className="col-3">Alamat</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyAddress}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Negara" value={detail?.companyCountry}/>
              </FieldWrapper>
              {/* <div className="col-3">Negara</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyCountry}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Provinsi" value={detail?.companyProvince}/>
              </FieldWrapper>
              {/* <div className="col-3">Provinsi</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyProvince}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Kab/Kota" value={detail?.companyCity}/>
              </FieldWrapper>
              {/* <div className="col-3">Kab/Kota</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyCity}
                />
                <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/>
              </div> */}
            
              <FieldWrapper additionalClass=''>
                <FieldElement title="Kelurahan" value={detail?.companyVillage}/>
              </FieldWrapper>
              {/* <div className="col-3">Kelurahan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyVillage}
                />
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Kecamatan" value={detail?.companyDistrict}/>
              </FieldWrapper>
              {/* <div className="col-3">Kecamatan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyDistrict}
                />
              </div> */}
            
              <FieldWrapper additionalClass=''>
                <FieldElement title="RT/RW" value={detail?.companyDetailRtRw}/>
              </FieldWrapper>
              {/* <div className="col-3">RT/RW</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyDetailRtRw}
                />
                {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/>
              </div> */}
              <FieldWrapper additionalClass=''>
                <FieldElement title="Kode Pos" value={detail?.companyPostalCode}/>
              </FieldWrapper>
              {/* <div className="col-3">Kode Pos</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyPostalCode}
                />
              </div> */}
            </div>
          </>
        );
        break;
    }
    return layoutOccupation;
  }

export default AmlOccupationMapper;