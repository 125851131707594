import React from "react";
import HomeNav from "./bottomnav/Homenav";
import HistoryNav from "./bottomnav/HistoryNav";
import PlusNav from "./bottomnav/PlusNav";
import NotifNav from "./bottomnav/NotifNav";
import useWindowDimensions from "app/utils/ScreenDimesions";
import ProfileNav from "./bottomnav/ProfileNav";

const MobileNav = () => {

    const { height, width } = useWindowDimensions();
    let path = window.location.pathname;

    return (
        <nav className="navbar navbar-dark bg-white navbar-expand d-md-none d-lg-none d-xl-none fixed-bottom">
              <ul className="navbar-nav nav-justified w-100">
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    <HomeNav
                      color={path == '/' ? '#F37F26' : '#404040'}
                    />
                    <div
                      className="mt-1"
                      style={
                        path == '/'
                          ? { color: '#F37F26' }
                          : { color: '#404040' }
                      }
                    >
                      Home
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/history" className="nav-link">
                    <HistoryNav
                      color={path == '/history' ? '#F37F26' : '#404040'}
                    />
                    <div
                      className="mt-1"
                      style={
                        path == '/history'
                          ? { color: '#F37F26' }
                          : { color: '#404040' }
                      }
                    >
                      History
                    </div>
                  </a>
                </li>
                <li
                  className="nav-item"
                  style={{ textAlign: 'center' }}
                >
                  <a
                    href="/draft/create/new"
                    className="nav-link"
                    style={
                      width < 426
                        ? {
                            left: '42%',
                            background: '#F37F26',
                            height: '55px',
                            width: '55px',
                            position: 'absolute',
                            top: -20,
                            borderRadius: '50%',
                          }
                        : {
                            left: '41.4%',
                            background: '#F37F26',
                            height: '55px',
                            width: '55px',
                            position: 'absolute',
                            top: -20,
                            borderRadius: '50%',
                          }
                    }
                  >
                    <div>
                      <PlusNav
                        color="#FFF"
                        className=""
                        style={{ marginTop: '30%' }}
                      />
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/notification" className="nav-link">
                    <NotifNav
                      color={
                        path == '/notification' ? '#F37F26' : '#404040'
                      }
                    />
                    <div
                      className="mt-1"
                      style={
                        path == '/notification'
                          ? { color: '#F37F26' }
                          : { color: '#404040' }
                      }
                    >
                      Notification
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/profile/me" className="nav-link">
                    <ProfileNav
                      color={
                        path == '/profile/me' ? '#F37F26' : '#404040'
                      }
                    />
                    <div
                      className="mt-1"
                      style={
                        path == '/profile/me'
                          ? { color: '#F37F26' }
                          : { color: '#404040' }
                      }
                    >
                      Profile
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
    )
}

export default MobileNav