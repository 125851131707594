import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BASE_URL } from 'app/utils/constant';
export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

export const Clfpr = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);

  const [profiledrop, setProfileDrop] = useState(false);

  const [formData, setFormData] = useState(initialFormData);
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
  const [filterAccountType, setfilterAccountType] = useState<any>('');
  const [listCL, setListCL] = useState<any>([]);

  const [filterOpeningBranch, setfilterOpeningBranch] = useState<any>('');
  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [sort, setSort] = useState<any>('REG-DATE');
  const [ascending, setAscending] = useState<any>(false);

  const [filterBankRdn, setfilterBankRdn] = useState<any>('');
  const [filterRegistrationPlatform, setfilterRegistrationPlatform] = useState<any>('');
  const [filterStatus, setfilterStatus] = useState<any>('');

  const [totalItems, setTotalItems] = useState<any>(0);

  const [dataUser, setDataUser] = useState<any>(null);

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([]);
      setDefault();
    } else {
      getSearch(props.location.state?.search);
    }
  }, [
    props.location.state?.search,
    sortType,
    sortPriority,
    sortLastModified,
    sortDate,
    page,
    size,
    totalPage,
  ]);

  useEffect(() => {
    // alert(props.location.state.search)
    getSearch(props.location.state?.search);
    // getListCL(page, limit);
    // getIsAdmin();
    // getIsAnnounce();
    // handleDownload();
  }, []);

  useEffect(() => {
    const getData = async () => {
      getIsAdmin();
      await getDataUser();
    };

    // call the function
    getData()
  }, []);

  useEffect(() => {
    if (dataUser) {
      getListCL(page, limit);
    }
  }, [
    sort,
    page,
    limit,
    ascending,
    formData,
    filterStatus,
    filterBankRdn,
    filterRegistrationPlatform,
    filterAccountType,
    filterOpeningBranch,
    dataUser,
  ]);

  const setDropdown = () => {
    setProfileDrop(!profiledrop);
  };

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const getListCL = (
    page,
    limit,
    status = '',
    bankRDN = '',
    statusFilter = ''
  ) => {
    setLoadingList(true);
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      nik: '',
      ypid: '',
      sid: '',
      name: '',
      username: '',
      email: '',
      phoneNumber: '',
      status: status,
      filterAccType: filterAccountType,
      filterMembership: '',
      filterBankRDN: filterBankRdn,
      filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      filterStatus: statusFilter,
      filterBranch: filterOpeningBranch,
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      employeeId: dataUser?.employeeNo,
      filterRegChannel: filterRegistrationPlatform,
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(`${BASE_URL.HOTSREGIS}api/backOffice/cl-fpr`, data, config)
      .then(function (res) {
        setListCL(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch(function (error) {
        console.log('LOG ERROR AXIOS ', error);
        // console.log('LOG ERROR AXIOS ', error);
      });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const getIsAnnounce = () => {
    axios
      .get('/services/uaadocservice/api/is-user-may-announce')
      .then((res) => {
        setIsAnnounce(res.data);
      })
      .catch((err) => {
        setIsAnnounce(false);
      });
  };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          setListSearch(result.data.content);
          setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  const changeSortType = () => {
    if (sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc');
    } else {
      setSortType('typeName.keyword,asc');
    }
  };

  const changeSortPriority = () => {
    if (sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc');
    } else {
      setSortPriority('priority.keyword,asc');
    }
  };

  const changeSortLastModified = () => {
    if (sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc');
    } else {
      setSortLastModified('lastApprovedByName.keyword,asc');
    }
  };

  const changeSortDate = () => {
    if (sortDate == 'date,asc') {
      setSortDate('date,desc');
    } else {
      setSortDate('date,asc');
    }
  };

  const goToPage = (id) => {
    setSearch('');
    history.push(`/preview/only/pdf/${id}`);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    // getListDocumentHistori(value, limit)
    getListCL(value, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    setPage(1);
    setLimit(show);
    getListCL(1, show);
    // getListDocumentHistori(1, show)
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    switch (e) {
      case 'NIK':
        searchByString = 'NIK';
        break;
      case 'YP ID':
        searchByString = 'YP-ID';
        break;
      case 'SID':
        searchByString = 'SID';
        break;
      case 'Name':
        searchByString = 'CUSTOMER-NAME';
        break;
      case 'Email':
        searchByString = 'EMAIL';
        break;
      case 'User ID':
        searchByString = 'USERNAME';
        break;
      case 'Phone':
        searchByString = 'PHONE-NUMBER';
        break;
      default:
        searchByString = '';
        break;
    }
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  const handleFilterAccountType = (e) => {
    setPage(1);
    setfilterAccountType(e);
  };

  const handleFilterBankRdn = (e) => {
    setPage(1);
    setfilterBankRdn(e);
  };

  const handleRegistrationPlatform = (e) => {
    console.log('SELECTED RegistrationPlatform', e);
    setPage(1);
    setfilterRegistrationPlatform(e);
  };

  const handleFilterOpeningBranch = (e) => {
    setPage(1);
    setfilterOpeningBranch(e);
  };

  const handleDownload = (nikValue: String, idValue: String) => {
    var data = JSON.stringify({
      nik: nikValue,
      customerId: idValue,
      // customerId: '634d2cf640c429171f655f81',
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/backOffice/download/cl-fpr-file`,
        data,
        config
      )
      .then(function (response) {
        let headerLine = response?.headers['content-disposition'];
        let startFileNameIndex = headerLine?.indexOf('"') + 1;
        let endFileNameIndex = headerLine?.lastIndexOf('"');
        let filename = headerLine?.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log('LOG ERROR AXIOS ', error);
      });
  };

  const handleDownloadBulk = () => {
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      nik: '',
      ypid: '',
      sid: '',
      name: '',
      username: '',
      email: '',
      phoneNumber: '',
      filterAccType: filterAccountType,
      filterMembership: '',
      filterBankRDN: filterBankRdn,
      filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      filterStatus: filterStatus,
      filterBranch: filterOpeningBranch,
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      employeeId: dataUser?.employeeNo,
      filterRegChannel: filterRegistrationPlatform,
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/backOffice/download/batch/cl-fpr-file`,
        data,
        config
      )
      .then(function (res) {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Approved:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error('Failed Approved: ' + res.data?.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(function (error) {
        console.log('LOG ERROR AXIOS ', error);
      });
  };

  function handleSendCLFPR(id: string) {
    // setLoadingList(true);
    axios
      .get(`${BASE_URL.HOTSREGIS}api/customer/cl-fpr/sendMail/${id}`)
      .then(async (res) => {
        if (res.data.code == 'SUCCESS') {
          // setLoadingList(false);
          toast.success('Success Send CL & FPR: ' + res.data?.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // setLoadingList(false);
          toast.error('Failed Send CL & FPR: ' + res.data?.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoadingList(false);
        toast.error('Failed Get Data: ' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function b64_to_utf8(str) {
    str = str.replace(/\s/g, '');
    return decodeURIComponent(escape(window.atob(str)));
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/pdf' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  return (
    <div className="px-5 pt-4" style={{ height: '100vh', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">CL & FPR</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-2 col-md-2 col-xxl-2 col-xl-2">
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
            <Dropdown.Item eventKey="YP ID">YP ID</Dropdown.Item>
            <Dropdown.Item eventKey="SID">SID</Dropdown.Item>
            <Dropdown.Item eventKey="Name">Customer Name</Dropdown.Item>
            <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
            <Dropdown.Item eventKey="User ID">User ID</Dropdown.Item>
            <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="app-search col-lg-7 col-md-7 col-xxl-9 col-xl-8">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                if (e.target.value === '') {
                  setPage(1);
                  setSearchValue(e.target.value);
                  getListCL(page, limit);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  setPage(1);
                  getListCL(page, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-xxl-2 col-xl-2">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              setPage(1);
              getListCL(page, limit);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>


      <div className="row mb-4">
        <div className="col">
          <h5>Filter</h5>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterAccountType == ''
                  ? 'Account Type'
                  : filterAccountType == 'All'
                    ? 'Account Type'
                    : filterAccountType
              }
              onSelect={handleFilterAccountType}
            >
              <Dropdown.ItemText>Select Account Type</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="Marjin">Marjin</Dropdown.Item>
              <Dropdown.Item eventKey="Syariah">Syariah</Dropdown.Item>
              <Dropdown.Item eventKey="Reguler">Reguler</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterBankRdn == ''
                  ? 'Bank RDN'
                  : filterBankRdn == 'All'
                    ? 'Bank RDN'
                    : filterBankRdn
              }
              onSelect={handleFilterBankRdn}
            >
              <Dropdown.ItemText>Select Bank RDN</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="BCA">BCA</Dropdown.Item>
              <Dropdown.Item eventKey="BRI">BRI</Dropdown.Item>
              <Dropdown.Item eventKey="MANDIRI">Mandiri</Dropdown.Item>
              <Dropdown.Item eventKey="CIMB">CIMB</Dropdown.Item>
              <Dropdown.Item eventKey="SINARMAS">Sinarmas</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterOpeningBranch == ''
                  ? 'Opening Branch'
                  : filterOpeningBranch == 'All'
                    ? 'Opening Branch'
                    : filterOpeningBranch
              }
              onSelect={handleFilterOpeningBranch}
            >
              <Dropdown.ItemText>Select Opening Branch</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="">All</Dropdown.Item>
              <Dropdown.Item eventKey="Bali">Bali</Dropdown.Item>
              <Dropdown.Item eventKey="Bandung Dago">Bandung Dago</Dropdown.Item>
              <Dropdown.Item eventKey="Batam">Batam</Dropdown.Item>
              <Dropdown.Item eventKey="Bekasi">Bekasi</Dropdown.Item>
              <Dropdown.Item eventKey="Bintaro">Bintaro</Dropdown.Item>
              <Dropdown.Item eventKey="Bogor">Bogor</Dropdown.Item>
              <Dropdown.Item eventKey="BSD">BSD</Dropdown.Item>
              <Dropdown.Item eventKey="Central Park">Central Park</Dropdown.Item>
              <Dropdown.Item eventKey="Depok">Depok</Dropdown.Item>
              <Dropdown.Item eventKey="Invesment House SCBD">Invesment House SCBD</Dropdown.Item>
              <Dropdown.Item eventKey="Kelapa Gading">Kelapa Gading</Dropdown.Item>
              <Dropdown.Item eventKey="KP Jambi">KP Jambi</Dropdown.Item>
              <Dropdown.Item eventKey="Lebak Bulus">Lebak Bulus</Dropdown.Item>
              <Dropdown.Item eventKey="Malang">Malang</Dropdown.Item>
              <Dropdown.Item eventKey="Medan Asia">Medan Asia</Dropdown.Item>
              <Dropdown.Item eventKey="Palembang">Palembang</Dropdown.Item>
              <Dropdown.Item eventKey="PIPM Balikpapan">PIPM Balikpapan</Dropdown.Item>
              <Dropdown.Item eventKey="PIPM Makasar">PIPM Makasar</Dropdown.Item>
              <Dropdown.Item eventKey="PIPM Padang">PIPM Padang</Dropdown.Item>
              <Dropdown.Item eventKey="Pontianak">Pontianak</Dropdown.Item>
              <Dropdown.Item eventKey="Puri Kencana">Puri Kencana</Dropdown.Item>
              <Dropdown.Item eventKey="Roxy">Roxy</Dropdown.Item>
              <Dropdown.Item eventKey="Semarang">Semarang</Dropdown.Item>
              <Dropdown.Item eventKey="Setia Budi">Setia Budi</Dropdown.Item>
              <Dropdown.Item eventKey="Solo">Solo</Dropdown.Item>
              <Dropdown.Item eventKey="Surabaya Kombes Duryat">Surabaya Kombes Duryat</Dropdown.Item>
              <Dropdown.Item eventKey="Surabaya Mayjen Sungkono">Surabaya Mayjen Sungkono</Dropdown.Item>
              <Dropdown.Item eventKey="Tangerang">Tangerang</Dropdown.Item>
              <Dropdown.Item eventKey="Yogyakarta">Yogyakarta</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={filterRegistrationPlatform == '' ? 'Registration Platform' : filterRegistrationPlatform}
              onSelect={handleRegistrationPlatform}
            >
              <Dropdown.ItemText>Select Registration Platform</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="OOA3">OOA3</Dropdown.Item>
              <Dropdown.Item eventKey="MSTOCK">MSTOCK</Dropdown.Item>
              <Dropdown.Item eventKey="NAVI">NAVI</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="col">
          <h5>Date</h5>
          <div className="row">
            <div className="col-3">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                // dateFormat="MMMM d, yyyy h:mm aa"
                // dateFormat="d MMMM yyyy - h:mm aa"
                dateFormat="d/MM/yyyy"
                // minDate={new Date()}
                selected={formData.startDate}
                onChange={(e) => {
                  setPage(1);
                  setFormData({
                    ...formData,
                    startDate: e,
                  });
                }}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.lastDate}
                maxDate={formData.lastDate}
              />
            </div>
            <div className="col-3">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                // dateFormat="MMMM d, yyyy h:mm aa"
                // dateFormat="d MMMM yyyy - h:mm aa"
                dateFormat="d/MM/yyyy"
                // minDate={new Date()}
                selected={formData.lastDate}
                onChange={(e) => {
                  setPage(1);
                  setFormData({
                    ...formData,
                    lastDate: e,
                  });
                }}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.lastDate}
                minDate={formData.startDate}
                maxDate={moment().toDate()}
              />
            </div>
            <div className="col-lg-3 col-md-3">
              <a
                className="btn btn-primary mx-2"
                style={{ color: 'white' }}
                onClick={() => {
                  handleDownloadBulk();
                }}
              >
                {' '}
                Download
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive">
          <thead
            className=""
            style={{
              background: '#F5F5F5',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              <th className="py-4 px-4">YP ID</th>
              <th className="px-4">Cust Name</th>
              <th className="px-4">Recommender</th>
              <th className="px-4">Opening Branch</th>
              <th className="px-4">Tipe Akun</th>
              <th className="px-4">Status CL & FPR</th>
              <th className="px-4 text-center" style={{ width: '4%' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                listCL.map((item, index) => {
                  return (
                    <tr className="" style={{ background: '#FFF', cursor: '' }}>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '150px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.ypId}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '2500px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.custName}
                      </td>
                      <td className="">{item?.recommender}</td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '250px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.branch}
                      </td>
                      <td className="">{item?.accountType}</td>
                      <td className="">{item?.clFprStatusInfo}</td>

                      <td className="">
                        <a
                          className="btn btn-light mx-2"
                          style={{
                            backgroundColor: '#002F48',
                            color: '#F5F5F5',
                          }}
                          onClick={() => handleSendCLFPR(item?.userId)}
                        >
                          {' '}
                          Resend
                        </a>
                        <a
                          className="btn btn-light mx-2"
                          style={{
                            backgroundColor: '#43936C',
                            color: '#F5F5F5',
                          }}
                          onClick={() =>
                            handleDownload(item?.nik, item?.userId)
                          }
                        >
                          {' '}
                          Download PDF
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>

      <Modal
        isOpen={showModal}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModal(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to delete{' '}
            <span className="font-weight-bold">"{selectedItem?.title}"</span>{' '}
            announcement ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => deleteFile(selectedItem?.id)}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModal(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Clfpr);
