import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { toast, ToastOptions } from 'react-toastify';
import Tabs from 'app/component/tabs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';
import AmlTabs from './aml-tabs';
import AmlDateRange from './aml-date-range';
import AmlDateRangeWrapper from './aml-date-range';
import AmlTable from './aml-table';
import DeleteConfirmationModal from './modal/aml-modal-delete-confirmation';
import AmlDropdown from './aml-dropdown';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

// Define the interface for the filter
interface Filter {
  startDate: Date;
  endDate: Date;
  sortBy: string;
  sortType: "asc" | "desc";  // Limiting sortType to "asc" or "desc"
  status: string[];          // Array of strings
  progress: string[];        // Array of strings
  searchBy: string;
  searchValue: string;
  page: number;
  size: number;
}

const initFilter: Filter = {
  startDate: moment().clone().startOf('week').add(1, 'days').toDate(),
  endDate: moment().clone().endOf('week').toDate(),
  sortBy: "createdDate",
  sortType : "asc",
  status: ["INIT"],
  progress: ["MAKER_ON_PROGRESS"],
  searchBy: "",
  searchValue: "",
  page: 1,
  size: 5
}

const dropdownItems = [
  {
    eventKey: "sid",
    name: "SID"
  }, 
  {
    eventKey: "customerName",
    name: "Customer Name"
  }
]

const toastConfig: ToastOptions = {
  position: 'top-left',
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const pageSizes = [5,10,15,20];

export interface IAMLProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const AML = (props: IAMLProps) => {
  let history = useHistory();
  const [loadingList, setLoadingList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
 
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [filter, setFilter] = useState(initFilter);

  const [sort, setSort] = useState<any>('REG-DATE');
  const [ascending, setAscending] = useState<any>(false);

  const [totalItems, setTotalItems] = useState<any>(0);

  useEffect(() => {
    let isMounted = true;
    if(isMounted){
      getListAML(page, limit)
    }
    return () => {isMounted = false}
  },[])

  useEffect(() => {
    let isMounted = true;
    if(isMounted){
      getListAML(page, limit)
    }
    return () => {isMounted = false}
  }, [filter]);


  const getListAML = (page, limit) => {
    setLoadingList(true);
    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/v1/aml/list`,
        filter
      )
      .then((res) => {
        setListAnnouncement(res.data.data);
        setTotalPage(res.data.totalPages);
        setTotalItems(res.data.total);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
      });
  };


  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setFilter({...filter, page: value})
    setPage(value);
  };

  const changeShow = (e) => {
    let show = e.target.value;
    setPage(1);
    setFilter({...filter,page: 1,  size: show})
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Annoucement', toastConfig);
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        getListAML(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', toastConfig);
        setShowModal(false);
      });
  };

  const goToDetail = (data) => {
    let languageValue = 'ID';
    axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${data?.regId}?language=${languageValue}`
      )
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          history.push(`/aml/view/${data.regId}`, {data});
        } else {
          toast.error('Error Reject:' + res.data.message, toastConfig);
        }
      })
      .catch((err) => {
        toast.error('Error Reject:' + err, toastConfig);
        history.push(`/aml/view/${data.customerId}`);
      });
  };


  return (
    <div className="px-3 pt-4" style={{ background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">AML</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-2 col-md-2 col-xxl-2 col-xl-2">
          <AmlDropdown items={dropdownItems} 
            onSelect={(eventKey, event) => {setFilter({...filter, searchBy: eventKey, searchValue: ""})}}
            />
        </div>
        <div className="app-search col-lg-7 col-md-7 col-xxl-9 col-xl-8">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                setFilter({...filter, searchValue : e.target.value})
              }}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  setPage(1);
                  getListAML(page, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-xxl-2 col-xl-2">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              getListAML(page, limit);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>
      <div className="row mb-4">
        
        <div className='col' >
          <AmlDateRangeWrapper 
              startDate={filter.startDate} 
              endDate={filter.endDate} 
              onChange={(start, end) => {
                setFilter({...filter, startDate: start, endDate: end})
              }}  
            />
        </div>
        <div className='col' ></div>
            
      </div>
      <div className="row">
        <AmlTabs onChange={(currentTab) => {
          setFilter({...filter, status: currentTab.status, progress: currentTab.progress, page: 1})}}
        />
      </div>

      <div className="row my-3">
        <div className='col'>
        <AmlTable
          listAnnouncement={listAnnouncement}
          loadingList={loadingList} 
          totalItems={totalItems}
          sort={sort} 
          setSort={setSort}
          ascending={ascending}
          setAscending={setAscending}
          goToDetail={goToDetail}/>

        </div>

        
      </div>

      <div className="row" style={{paddingBottom: '100px'}}>
        <div className="col-xs-12 col-md-6 col-lg-6">
          <div className="d-block pb-1">
            <label htmlFor="priority" >
              Show
            </label>
            &nbsp;
            <select
              style={{maxWidth: '50px'}}
              name="show"
              value={filter.size}
              className="custom-select"
              onChange={(e) => {changeShow(e)}}>
              {pageSizes.filter(e => e < totalItems).map((e, i) => {
                  return <option key={i} value={e}>{e}</option>
                })}
              {
                totalItems < pageSizes[pageSizes.length-1] && <option value={totalItems}>{totalItems}</option>
              }
            </select>
            &nbsp;
            <label htmlFor="priority" className="">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={filter.page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>
      <DeleteConfirmationModal 
        showModal={showModal}
        handleClose={handleClose}
        selectedItem={selectedItem}
        loadingDelete={loadingDelete}
        deleteFile={deleteFile}
        setShowModal={setShowModal}
        setItemSelected={setItemSelected}
      />
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AML);
