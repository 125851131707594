import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, ToastOptions } from 'react-toastify';
import { Accordion, Carousel } from 'react-bootstrap';
import CustomReactPanZoom from 'app/component/custom-react-pan-zoom';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';
import AmlApprovalTable from './table/aml-approval-table';
import AmlModalMaker from './modal/aml-modal-maker';
import AmlButtonMaker from './button/aml-button-maker';
import AmlButtonReviewer from './button/aml-button-reviewer';
import { FieldElement, FieldWrapper } from '../aml-approver/detail/aml-approver-detail-page';
import AmlOccupationMapper from '../aml-approver/detail/aml-occupation-mapper';
import { getAmlApprovalHistories } from './api/aml-api';
import AmlModalReviewer from './modal/aml-modal-reviewer';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
  match?: any;
}

const initApprovalList = [
  {
    createdDate: null,
    username: null,
    status: null,
    approval: null,
    notes: null
  }
]

interface AmlProgress {
  code: string;
  name: string;
}

const progressList: AmlProgress[] = [
  {
    code: "MAKER_ON_PROGRESS",
    name: "Menunggu Review"
  },
  {
    code: "REVIEWER_ON_PROGRESS",
    name: "Menunggu Persetujuan"
  },
  {
    code: "APPROVER_ON_PROGRESS",
    name: "Menunggu Persetujuan Final"
  }
]

const toastConfig: ToastOptions = {
  position: 'top-left',
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const AMLView = (props: IHeaderProps) => {
  let history = useHistory();
  let params = useParams();

  const [loadingList, setLoadingList] = useState(false);

  const [index, setIndex] = useState(0);
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(
    false
  );
  const [detail, setDetail] = useState<any>([]);
  const [listApproval, setListApproval] = useState<any>(initApprovalList);
  const [urlImageKTP, setUrlImageKTP] = useState<any>(null);
  const [urlImageNPWP, setUrlImageNPWP] = useState<any>(null);
  const [urlImageSelfie, setUrlImageSelfie] = useState<any>(null);
  const [urlImageTTD, setUrlImageTTD] = useState<any>(null);

  const [sort, setSort] = useState<any>('createdDate');
  const [ascending, setAscending] = useState<boolean>(false);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };



  const [dataUser, setDataUser] = useState<any>(null);
  const [progress, setProgress] = useState<AmlProgress>(progressList[0]);
  const [showModalMaker, setShowModalMaker] = useState<boolean>(false);
  const [showModalReviewer, setShowModalReviewer] = useState<boolean>(false);
  const [modalReviewerStat, setModalReviewerStat] = useState<string>("APPROVE")

  const handleCloseModalMaker = () => setShowModalMaker(false)
  const handleCloseModalReviewer = () => setShowModalReviewer(false)

  useEffect(() => {
    getDataUser();
  }, []);

  useEffect(() => {
  }, [listApproval, progress]) 

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  const getDetailAnnouncement = async (id) => {
    let languageValue = 'ID';
    return axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${id}?language=${languageValue}`
      )
  };

  function getImage(imageId: string) {
    return axios
      .get(`${BASE_URL.HOTSREGIS}api/backOffice/load-image/${imageId}`)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        
        setLoadingList(false);
      });
  }

  useEffect(() => {
    let isMounted = true
    getAmlApprovalHistories(params.id).then((res) => {
      if(isMounted){
        let username = res.data[0].username
        let approvalResult = res.data[0].approvalHistoryDetails;
        let currentProgress = res.data[0].amlCurrentProcess;
        let approvalState = approvalResult.map(e => {
          return {
            createdDate: e.createdDate,
            username: username,
            status: e.progress,
            action: e.action,
            approval: e.amlActor.empNm,
            notes: e.notes,
            amlFile: e.amlFile
          }
        })
        
        setListApproval(approvalState);
        let current = progressList.filter(e => e.code === currentProgress)[0]
        setProgress(current);
      }
    }).catch((err) => {
      history.replace("/not-found")
    });
    getDetailAnnouncement(params.id).then(async (res) => {
          
      setLoadingList(true);
      setDetail(res.data.data);
      if (res.data.data.ktpImage != '-') {
        setUrlImageKTP(
          'data:image/png;base64,' + (await getImage(res.data.data.ktpFile))
        );
      }
      if (res.data.data.npwpImage != '-') {
        setUrlImageNPWP(
          'data:image/png;base64,' + (await getImage(res.data.data.npwpFile))
        );
      }
      if (res.data.data.selfieImage != '-') {
        setUrlImageSelfie(
          'data:image/png;base64,' +
            (await getImage(res.data.data.selfieFile))
        );
      }
      if (res.data.data.ttdImage != '-') {
        setUrlImageTTD(
          'data:image/png;base64,' +
            (await getImage(res.data.data.signatureUrl))
        );
      }
      
      setLoadingList(false);
      
    })
    .catch((err) => {
      setLoadingList(false);
    });

    return () => { isMounted = false };
  }, []);

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleClose = () => {
    setShowModalPreviewImage(false);
  };

  function handleRejectList(item: any) {
    var rejectReason = '';

    if (item?.listReject != null) {
      rejectReason = item?.listReject[0]?.rejectReasonDetail;
    } else {
      //Masuk ke else
    }

    return rejectReason;
  }

  const afterSubmit = (data) => {
    if (data.code == 'SUCCESS') {
      toast.success('Success Approve', toastConfig);
        
    } else {
      toast.error('Failed Approve:' + data.message, toastConfig);
    }

    setShowModalMaker(false)
    setShowModalReviewer(false)
    getApprovalHistory(params.id)
  }

  const getApprovalHistory = async (id) => {
    await getAmlApprovalHistories(id).then((res) => {

        let username = res.data[0].username
        let approvalResult = res.data[0].approvalHistoryDetails;
        let currentProgress = res.data[0].amlCurrentProcess;
        let approvalState = approvalResult.map(e => {
          return {
            createdDate: e.createdDate,
            username: username,
            status: e.progress,
            action: e.action,
            approval: e.amlActor.empNm,
            notes: e.notes
          }
        })
        
        setListApproval(approvalState);
        let current = progressList.filter(e => e.code === currentProgress)[0]
        setProgress(current);
      })
      .catch((err) => {
      });
  };

  const handleButtonMaker = (e) => setShowModalMaker(true)
  const handleButtonReviewer = (action) => {
    setShowModalReviewer(true);
    setModalReviewerStat(action)
  }
  

  return !loadingList ? (
    <div className="px-3 pt-4" style={{ background: '#fff', height: '100%' }}>
      <button className="btn px-0" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-6">
          <h1 className="mb-0 font-size-32">{progress?.name}</h1>
        </div>
        <div className="col-6 text-end">
          {/* <h1 className="mb-0 font-size-32">{title}</h1> */}

          {progress?.code === "MAKER_ON_PROGRESS" && <AmlButtonMaker onClick={e => handleButtonMaker(e)} />}
          {progress?.code === "REVIEWER_ON_PROGRESS" && <AmlButtonReviewer onClick={action => handleButtonReviewer(action)} />}
        </div>
        <div className="row mt-3">
          <p className="" style={{ fontSize: '18px' }}>
            {' '}
            {detail?.name}
          </p>
        </div>
        
      </div>
      <div className="row mt-3">
        <div className="col-12 py-2">
          <Accordion defaultActiveKey={['1', '7']} alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Dokumen</Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
              <div className="row align-items-center mb-3">
                
                {[
                  { label: 'KTP', imageUrl: urlImageKTP, index: 0 },
                  { label: 'NPWP', imageUrl: urlImageNPWP, index: 1 },
                  { label: 'Selfie', imageUrl: urlImageSelfie, index: 2 },
                  { label: 'Tanda Tangan', imageUrl: urlImageTTD, index: 3 },
                ].map(({ label, imageUrl, index }) => (
                  <FieldWrapper key={label} additionalClass='d-flex flex-column gap-2 py-2'>
                    
                    <div className='d-flex flex-column' style={{minHeight: '300px', maxHeight: '300px'}}>
                      <span>{label}</span>
                    
                      <img
                          className="image-button w-100"
                          style={{maxHeight: '250px', objectFit: 'contain'}}
                          src={imageUrl}
                          alt={imageUrl? 'Tidak ada dokumen':label}
                          onClick={() => {
                            setShowModalPreviewImage(true);
                            handleSelect(index, imageUrl);
                          }}
                        />
                    </div>
                  </FieldWrapper>
                ))}
              </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <h5>Score</h5>
              </Accordion.Header>
              <Accordion.Body className="" style={{ background: '#fff' }}>
                <div className="row align-items-center ">
                  <FieldWrapper >
                    <FieldElement title="AML Score" 
                      value={(Math.round(detail?.score * 100) / 100).toFixed(
                        2
                      )}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Risk Status" value={detail?.riskStatus}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Liveness Score" value={detail?.faceComparisonScore}/>
                  </FieldWrapper>
                  
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h5>Data Nasabah</h5>
              </Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                <div className="row align-items-center  mb-3">
                  <FieldWrapper >
                    <FieldElement title="User ID" value={detail?.username}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nomor Identitas" value={detail?.idNumber}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nama Lengkap (Sesuai KTP)" value={detail?.name}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement 
                      title="Phone Number" 
                      value={(detail?.dialCode + detail?.phoneNumber)
                        .toString()
                        .replace(/(?<=\d\d)\d(?=\d{2})/g, '*')}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Tempat & Tanggal Lahir" 
                      value={`${detail?.placeOfBirth}, ${formatDate(detail?.birthday, 'DD MMM YYYY')}`}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="NPWP" value={detail?.npwpId}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Email" 
                      value={detail?.email}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Negara tempat lahir" value={detail?.countryOfBirth}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Agama" value={detail?.religionName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nama Gadis Ibu Kandung" value={detail?.motherName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Jenis Kelamin" value={detail?.genderName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Status Rumah" value={detail?.ownershipOfHouseStatusName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Status Pernikahan" value={detail?.maritalStatusName}/>
                  </FieldWrapper>
                  {detail?.maritalStatusName?.toUpperCase() === 'MENIKAH' && (
                    <>
                      <FieldWrapper additionalClass=''>
                        <FieldElement title="Nama Pasangan" value={detail?.spouseName}/>
                      </FieldWrapper>
                    </>
                  )}
           
                  <div className="col-12 py-2">
                    <h5>Alamat Sesuai KTP</h5>
                  </div>
             
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Alamat" value={detail?.address}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Negara" value={detail?.country}/>
                  </FieldWrapper>
                 
                <FieldWrapper additionalClass=''>
                    <FieldElement title="Provinsi" value={detail?.province}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kab/Kota" value={detail?.city}/>
                  </FieldWrapper>
                
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kelurahan" value={detail?.village}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kecamatan" value={detail?.district}/>
                  </FieldWrapper>
              
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="RT/RW" value={detail?.detailRtRw}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kode Pos" value={detail?.postalCode}/>
                  </FieldWrapper>
                 
                  <div className="col-12 py-2">
                    <h5>Alamat Domisili</h5>
                  </div>
             
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Alamat" value={detail?.domicileAddress}/>
                  </FieldWrapper>
                
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Negara" value={detail?.domicileCountry}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Provinsi" value={detail?.domicileProvince}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kab/Kota" value={detail?.domicileCity}/>
                  </FieldWrapper>
                 
                <FieldWrapper additionalClass=''>
                    <FieldElement title="Kelurahan" value={detail?.domicileVillage}/>
                  </FieldWrapper>
                 
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kecamatan" value={detail?.domicilDistrict}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="RT/RW" value={detail?.domicileDetailRtRw}/>
                  </FieldWrapper>
                  
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Kode Pos" value={detail?.domicilePostalCode}/>
                  </FieldWrapper>
                 
                  <div className="col-12 py-2">
                    <h5>Alamat Surat Menyurat</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Alamat Surat Menyurat" value={detail?.mailingAddressName}/>
                  </FieldWrapper>
                </div>
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h5>Data Bank</h5>
              </Accordion.Header>
              <Accordion.Body className="" style={{ background: '#fff' }}>
                <div className="row align-items-center mb-3">
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Bank RDN" value={detail?.bankRDNName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="No Rekening RDN" value={detail?.rdnAccount}/>
                  </FieldWrapper>
                  <div className="col-12 py-2">
                    <h5>Rekening Pencairan</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Bank" value={detail?.bankDestinationName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="No Rekening" value={detail?.accountBankNo}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nama Pemilik Rekening" value={detail?.accountBankName}/>
                  </FieldWrapper>
                  <div className="col-12 py-2">
                    <h5>Lain - lain</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Nomor Rekening Sekarang" value={detail?.accountBankNoExisting}/>
                  </FieldWrapper>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h5>Data Pekerjaan</h5>
              </Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                {/* Checking Pekerjaan */}
                <AmlOccupationMapper occupationCode={detail?.occupation} detail={detail} /> 
                {/* Close Checking Pekerjaan */}
                <div className="row align-items-center mb-3">
                  <div className="col-12 py-2">
                    <h5>Pendapatan</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Pendapatan Kotor Per Tahun" value={detail?.salaryRangeName}/>
                  </FieldWrapper>
                  <div className="col-12 py-2">
                    <h5>Sumber Dana & Tujuan Investasi</h5>
                  </div>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Sumber Dana" value={detail?.sourceOfIncomeName}/>
                  </FieldWrapper>
                  <FieldWrapper additionalClass=''>
                    <FieldElement title="Tujuan Investasi" value={detail?.investmentGoalName}/>
                  </FieldWrapper>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Approval History</Accordion.Header>
              <Accordion.Body style={{ background: '#fff' }}>
                <div className="row align-items-center" style={{paddingBottom: '100px'}}>
                  <AmlApprovalTable
                    listApproval={listApproval} 
                    sort={sort} 
                    ascending={ascending} 
                    setSort={setSort} 
                    setAscending={setAscending} 
                    formatDate={formatDate} 
                    handleRejectList={handleRejectList}
                    />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      {/* Modal */}
      {progress?.code === "MAKER_ON_PROGRESS" && <AmlModalMaker 
        refId={props.match.params.id}
        isOpen={showModalMaker} 
        handleCloseModalMaker={handleCloseModalMaker} 
        setIsOpenModalMaker={setShowModalMaker} 
        onSubmit={data => {afterSubmit(data)}}
      />}

      {progress?.code === "REVIEWER_ON_PROGRESS" && <AmlModalReviewer
        refId={props.match.params.id}
        stat={modalReviewerStat}
        isOpen={showModalReviewer} 
        handleCloseModalReviewer={handleCloseModalReviewer} 
        setIsOpenModalReviewer={setShowModalReviewer} 
        onSubmit={(data) => afterSubmit(data)}
      />}

      <Modal
        isOpen={showModalPreviewImage}
        toggle={handleClose}
        size="xl"
        centered
        onClosed={() => {
          setShowModalPreviewImage(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <Carousel
            variant="dark"
            activeIndex={index}
            onSelect={handleSelect}
            data-bs-interval="false"
            interval={null}
          >
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/ktp-1.jpeg"
                image={urlImageKTP}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/npwp-1.jpeg"
                image={urlImageNPWP}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/selfie-1.jpeg"
                image={urlImageSelfie}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/ttd-1.jpeg"
                image={urlImageTTD}
                alt="Image alt text"
              />
            </Carousel.Item>
          </Carousel>
          {/* <div className="text-center mt-5">
              <button className="btn btn-success mx-2 px-4" onClick={() => {
                
                setShowModalPreviewImage(false);
                setItemSelected(null)
              }} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
              </button >
              <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                setShowModalPreviewImage(false);
                setItemSelected(null)
              }}>
                Cancel
              </button >
            </div> */}
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>
  ) : (
    <>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
    </>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AMLView);