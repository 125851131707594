import React, { useEffect, useRef, useState } from "react";



const AmlApproverButton = ({onClick}) => {
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const stickyRef = useRef(null)
    let shadow = '0px -4px 3px 0px rgba(0,0,0, 0.1)'

    const onClickButtonMaker = (action) => onClick(action)
    
    
    useEffect(() => {
        function onScroll() {
            let currentPosition = document.documentElement.scrollTop
            
            if (currentPosition > scrollTop) {
              // downscroll code
              setScrolling(false);
            } else {
              // upscroll code
              setScrolling(true);
            }
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
          }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop])

    // const style
    return (
        <div ref={stickyRef} className='position-fixed w-100 bottom-0 bg-white' 
        style={{
            zIndex: 3,
            boxShadow: shadow, 
            MozBoxShadow: shadow, 
            WebkitBoxShadow: shadow}} >
            <div className="container">
                <div className="row">
                <div className="col-xs-12 py-2 d-flex gap-2 bg-white align-items-end gap-2" >
                        
                    <a className="btn btn-primary flex-grow-1 text-white text-center"
                        onClick={() => onClickButtonMaker("REVISE")}
                        style={{ background: '#CC7633' }}>
                        Revisi
                    </a>
                    <a className="btn btn-danger flex-grow-1 text-white text-center"
                        onClick={() => onClickButtonMaker("REJECT")}
                        style={{ background: '#CB3A31' }}>
                        Tolak
                    </a>
                    <a className="btn btn-success flex-grow-1 text-white text-center"
                        onClick={() => onClickButtonMaker("APPROVE")}
                        style={{ background: '#43936C' }}>
                        Setujui
                    </a>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AmlApproverButton;