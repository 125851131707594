import './header.scss';

import '../../../../../../../scss/app.min.scss'

// import avatar1 from '../../../../../../../images/users/avatar-5.jpg';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { useHistory } from "react-router-dom";
import LoadingBar from 'react-redux-loading-bar';

import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { logout } from 'app/shared/reducers/authentication';
import LogoutIcon from 'app/component/logout-icon';
import { toast } from 'react-toastify';
import MiraeLogo from 'app/entities/aml-approver/asset/mirae-logo';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
 
  openMenu?: () => void
}


const Header = (props: IHeaderProps) => {
  let history: any = useHistory();
  const [profiledrop, setProfileDrop] = useState(false);
  const [dataUser, setDataUser] = useState<any>(null)

  const setDropdown = () => {
    setProfileDrop(!profiledrop)
  }

  const handleLogout = async () => {
    await props.logout()
    await history.replace('/')
    await delayTime(2000)
    await toast.success('Berhasil Logout', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

    useEffect(() => {
        console.log('test', history.location)
    }, []);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = 'https://farmasi.ump.ac.id/data/foto_berita/def(10).jpg';
    event.currentTarget.onerror = null;
  };

  return (
    <>
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      {/* <Navbar dark expand="sm" fixed="top" className="dms-navbar"> */}
      <header id="page-topbar">
        <div className="navbar-header" style={{ backgroundColor: '#F5F5F5 !important' }}>
          <div className="d-flex">
            <div className="navbar-brand-box">
              <MiraeLogo />
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block"  >
              <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onMouseOver={() => setDropdown()}>
                <img className="rounded-circle header-profile-user" onError={(e) => imageOnErrorHandler(e)}  src={dataUser?.profilePicture ? `data:image/png;base64, ${dataUser.profilePicture}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                  alt="Header Avatar" />
                <span className="d-none d-xl-inline-block ml-1">{props.account.firstName ? props.account.firstName : 'User'}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right text-wrap ${profiledrop ? "d-block" : ""}`} onMouseLeave={() => setDropdown()}>
                <div className={`px-2 mt-2 mb-4 d-flex flex-row`}>
                  <div className=''>
                    <img className="rounded-circle header-profile-user" onError={(e) => imageOnErrorHandler(e)} src={dataUser?.profilePicture ? `data:image/png;base64, ${dataUser.profilePicture}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                      alt="Header Avatar" />
                  </div>
                  <div className='px-2 text-wrap'>
                    <div className="d-none d-xl-inline-block ml-1 font-weight-bold">{props.account.firstName ? props.account.firstName : 'User'}</div>
                    <div className="d-none d-xl-inline-block ml-1 tex-sm text-wrap">{props.account.email ? props.account.email : 'email@user.com'}</div>
                  </div>
                </div>
                <div className="dropdown-item my-1 py-2" style={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
                  <LogoutIcon className='mr-2' />
                  Logout
                </div>
              </div>
            </div> 
          </div>
          {/* </Navbar> */}
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  logout
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header)

