import React, { useEffect, useState } from 'react';
import { downloadAmlFile } from '../api/aml-api';
import '../styles/aml.scss'
import { Spinner } from 'reactstrap';

const TableHeader = ({ label, sortKey, sort, ascending, onSort }) => {
  return (
    <th
      className="py-4 px-4"
      style={{ cursor: 'pointer' }}
      onClick={() => onSort(sortKey)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }} className="gap-4">
        <span className="mr-1">{label}</span>
        <span className="mt-1">
          {sort === sortKey && ascending === true && (
            <i className="bx bxs-up-arrow" style={{ fontSize: '10px' }}></i>
          )}
          {sort === sortKey && ascending === false && (
            <i className="bx bxs-down-arrow" style={{ fontSize: '10px' }}></i>
          )}
          {sort !== sortKey && (
            <i className="bx bxs-sort-alt text-right" style={{ fontSize: '14px' }}></i>
          )}
        </span>
      </div>
    </th>
  );
};


const getDownloadFilename = (amlFile?) => {
  let filename = amlFile.name
  let splitArr = filename.split("/")
  return splitArr[splitArr.length -1]
}

const TableRow = ({ item, index, formatDate, handleRejectList }) => {
  const [load, setLoad] = useState(false);

  const onclick = (e) => {
    setLoad(true)
    downloadAmlFile(item.amlFile.url).then((response) => {
      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers['Content-Disposition'];
      let filename = `${getDownloadFilename(item.amlFile)}.pdf`; // Default filename

      if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (filenameMatch.length > 1) {
              filename = filenameMatch[1];
          }
      }

      // Create a URL from the response data
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element
      const fileLink = document.createElement('a');

      // Set the download attribute with a filename
      fileLink.href = fileURL;
      fileLink.target="blank"
      fileLink.setAttribute('download', filename);

      // Trigger the link to download
      document.body.appendChild(fileLink);
      fileLink.click();

      // Clean up and remove the link
      fileLink.remove();
  })
  .catch((error) => {
      console.error('There was an error downloading the PDF!', error);
  }).finally(()=> setLoad(false));
  }

  

  return (
    <tr style={{ background: '#FFF' }}>
      <td className="text-center">{index + 1}</td>
      <td style={tableCellStyle(200, 250)}>{formatDate(item?.createdDate, 'DD/MM/YY HH:mm')}</td>
      <td style={tableCellStyle(200, 250)}>{item?.username}</td>
      <td style={tableCellStyle(300, 400)}>{item?.status}</td>
      <td style={tableCellStyle(200, 300)}>{item?.action}</td>
      <td style={tableCellStyle(200, 300)}>{item?.approval}</td>
      <td style={tableCellStyle(400, 600)} >
        <div className='d-flex flex-column '>
          <div>
            {item?.notes}
          </div>
          
            {item?.amlFile && <div className='aml-document'>
              <button
                className="btn btn-icon"
                onClick={onclick}
                style={{ border: 'none', background: 'transparent', paddingLeft: 0 }}
              >
                {load ? <Spinner/>: <i style={{fontSize: '20px'}} className="fas fa-file-pdf"></i>}
                <span style={{paddingLeft: '10px'}}>{getDownloadFilename(item?.amlFile)}.pdf</span>
              </button>
              
            </div>
            }
        </div>
        
        {/* <Notes item={item} /> */}
        
      </td>
    </tr>
  );
};

const tableCellStyle = (minWidth, maxWidth) => ({
  wordBreak: 'break-all',
  minWidth: `${minWidth}px`,
  maxWidth: `${maxWidth}px`,
  whiteSpace: 'pre-wrap',
});

const AmlApprovalTable = ({ listApproval, sort, ascending, setSort, setAscending, formatDate, handleRejectList }) => {
  const handleSort = (key) => {
    setSort(key);
    setAscending(sort === key ? !ascending : true);
  };

  return (
    <table className="table table-centered table-nowrap mb-0 table-responsive">
      <thead
        style={{
          background: '#fff',
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}
      >
        <tr>
          <th className="py-4 px-4">No</th>
          <TableHeader
            label="Created Date"
            sortKey="createdDate"
            sort={sort}
            ascending={ascending}
            onSort={handleSort}
          />
          <th className="px-4">User ID</th>
          <TableHeader
            label="Status"
            sortKey="status"
            sort={sort}
            ascending={ascending}
            onSort={handleSort}
          />
          <TableHeader
            label="Action"
            sortKey="action"
            sort={sort}
            ascending={ascending}
            onSort={handleSort}
          />
          <TableHeader
            label="Approval"
            sortKey="createdBy"
            sort={sort}
            ascending={ascending}
            onSort={handleSort}
          />
          <th className="px-4">Notes</th>
        </tr>
      </thead>
      <tbody style={{ background: '#fff' }}>
        {listApproval.map((item, index) => (
          <TableRow
            key={index}
            item={item}
            index={index}
            formatDate={formatDate}
            handleRejectList={handleRejectList}
          />
        ))}
      </tbody>
    </table>
  );
};

export default AmlApprovalTable;