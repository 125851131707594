import React from "react";

const AmlButtonReviewer = ({onClick}) => {

    const onClickButtonMaker = (action) => onClick(action)

    return (
        <>
            <a
                className="btn btn-primary text-white text-end mr-2"
                onClick={() => onClickButtonMaker("REVISE")}
                style={{ background: '#CC7633' }}>
                Revisi
            </a>
            <a
                className="btn btn-danger text-white text-end mr-2"
                onClick={() => onClickButtonMaker("REJECT")}
                style={{ background: '#CB3A31' }}>
                Tolak
            </a>
            <a
                className="btn btn-success text-white text-end"
                onClick={() => onClickButtonMaker("APPROVE")}
                style={{ background: '#43936C' }}>
                Setujui
            </a>
            
    </>
    )

}

export default AmlButtonReviewer;