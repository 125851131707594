import 'react-toastify/dist/ReactToastify.css';
import './../content/css/loading.css';
import './../content/css/miraeloading.css';
import '../../../../scss/app.scss';
//import '../../../../scss/bootstrap.scss';
import '../../../../scss/icons.scss';
import { Chart, ArcElement } from 'chart.js';

import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { Card, CardGroup, Nav, NavLink, NavItem, Col, Row } from 'reactstrap';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Loginx from 'app/modules/login/loginx';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import HeaderPublic from 'app/shared/layout/header-public/header';
import loginx from 'app/modules/login/loginx';
import documentverified from './modules/documentverified';
import NotFound from './entities/document/NotFound';
import useWindowDimensions from './utils/ScreenDimesions';
import HeaderMobileNew from './shared/layout/header/header-mobile';
import documentverifiedprivate, {
  DocumentVerifiedPrivate,
} from './modules/documentverifiedprivate';
import HomeNav from './component/bottomnav/Homenav';
import HistoryNav from './component/bottomnav/HistoryNav';
import NotifNav from './component/bottomnav/NotifNav';
import ProfileNav from './component/bottomnav/ProfileNav';
import PlusNav from './component/bottomnav/PlusNav';
import AmlOtpPage from './entities/aml-approver/otp/aml-otp-page';
import { AmlApproverDetailPage } from './entities/aml-approver/detail/aml-approver-detail-page';
import MobileNav from './component/mobile-nav';

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  const { height, width } = useWindowDimensions();
  const [isOpen, setOpen] = useState<any>(false);
  let path = window.location.pathname;

  useEffect(() => {
    props.getSession();
    // props.getProfile();
  }, []);

  const changeOpen = () => {
    setOpen(!isOpen);
  };

  if (props.loading) {
    return (
      <div id="">
        <div className="centerUp">
          <img src="./content/images/logoloading.png" />

          <div className="centerLoadingBar meter">
            <span className="progress"></span>
          </div>
        </div>
        <img src="./content/images/supportby.png" className="centerDown" />
      </div>
    );
  } else {
    return (
      <Router basename={baseHref}>
        <ToastContainer
          position={toast.POSITION.TOP_LEFT}
          className="toastify-container"
          toastClassName="toastify-toast"
        />
        {props.isAuthenticated ? (
          <> 
            <SideBar toggle={true} isOpen={isOpen} />
            <div
              className="main-content"
              style={{ background: '#fff', marginTop: '70px' }}>
              <ErrorBoundary>
                {width < 426 ? (
                <HeaderMobileNew
                  isAuthenticated={props?.isAuthenticated}
                  isAdmin={props.isAdmin}
                  currentLocale={props.currentLocale}
                  onLocaleChange={props.setLocale}
                  ribbonEnv={props.ribbonEnv}
                  isInProduction={props.isInProduction}
                  isSwaggerEnabled={props.isSwaggerEnabled}
                  openMenu={changeOpen}
                />
              ) : (
                <Header
                  isAuthenticated={props?.isAuthenticated}
                  isAdmin={props.isAdmin}
                  currentLocale={props.currentLocale}
                  onLocaleChange={props.setLocale}
                  ribbonEnv={props.ribbonEnv}
                  isInProduction={props.isInProduction}
                  isSwaggerEnabled={props.isSwaggerEnabled}
                />
              )}
                <AppRoutes />
              </ErrorBoundary>

              {/* <Footer /> */}
            </div>
            <MobileNav />
          </>
        ) : (
          <>
          <Switch>
            <ErrorBoundaryRoute path="/" component={loginx} exact />
            <ErrorBoundaryRoute
              path="/document/verified/:id"
              exact
              component={documentverified}
            />
            <ErrorBoundaryRoute path="/documentapproval/aml/:id" component={AmlOtpPage} exact />
            <ErrorBoundaryRoute path="/documentapproval/aml/:id/:key" component={AmlApproverDetailPage} exact />
            <ErrorBoundaryRoute path="*" exact component={NotFound}/>
          </Switch>
          </>
        )}
      </Router>
    );
  }
};

const mapStateToProps = ({
  authentication,
  applicationProfile,
  locale,
}: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  loading: authentication.loading,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.ADMIN,
    AUTHORITIES.CS,
    AUTHORITIES.OE,
    AUTHORITIES.KYC,
    AUTHORITIES.ADMIN_OOA3,
    AUTHORITIES.IT_SUPPORT,
    AUTHORITIES.AML,
  ]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
