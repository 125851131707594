import React, {useEffect, useState} from "react";
import DatePicker from 'react-datepicker';
import { Col, Container, Row } from "reactstrap";
// import '../../../content/css/reacstrapcustom.css'

export function AmlDateRange({ startDate, endDate, onChange }){
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
	useEffect(() => {
	}, [startDate, endDate])

  const handleStartDateChange = (date) => {
    setStart(date);
    if(!isValidDateRange(date, end)) {
        setEnd(date)
    }
    onChange(date, end);
  };

  const handleEndDateChange = (date) => {
    setEnd(date);
    onChange(start, date);
  };

  function isValidDateRange(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
  
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return false;
    }
  
    return startDate <= endDate;
  }

  return (
    <Row>
				<Col>
					<DatePicker 
					 	id="dropdown-basic-button"
						selected={start}
						onChange={handleStartDateChange}
						selectsStart
						startDate={start}
						endDate={end}
						dateFormat="yyyy-MM-dd"
						placeholderText="Start Date"
					/>
				</Col>
				<Col>
					<DatePicker
						id="dropdown-basic-button"
						selected={end}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={start}
						endDate={end}
						minDate={start}
						dateFormat="yyyy-MM-dd"
						placeholderText="End Date"
					/>
				</Col>
    </Row>
  );
}

export default function AmlDateRangeWrapper(props){
	return (
		<Row>
			<Col xs={12}><h1 className="font-size-14">Tanggal</h1></Col>
			<Col xs={12}>
				<AmlDateRange {...props} />
			</Col>
		</Row>
	)
}