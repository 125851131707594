import Axios from "axios";
import React, {useEffect, useState} from "react";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

import {
    CardGroup,
    Row,
    Col,
    Alert,
    Tooltip,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Input,
    ModalFooter,
    Button,
    Spinner,
    Label,
    Container,
    FormText,
  } from 'reactstrap';
import { executeAmlAction } from "../api/aml-api";


interface MakerForm {
    notes: string;
    documentNumber: string;
    uploadDoc: any;
    isApproved: string;
}

const initForm: MakerForm = {
    notes: null,
    documentNumber: null,
    uploadDoc: null, 
    isApproved: "false"
}



const AmlModalMaker = ({refId, isOpen, handleCloseModalMaker, setIsOpenModalMaker, onSubmit}) => {
    const history = useHistory();
    const [makerForm, setMakerForm] = useState<MakerForm>(initForm)

    const handleNoteChange = (e) => setMakerForm({...makerForm, notes: e.target.value})
    const handleDocumentNoChange = (e) => setMakerForm({...makerForm, documentNumber: e.target.value})
    const handleUpload = (e) => setMakerForm({...makerForm, uploadDoc: e.target.files[0]})
    const handleApprovedChange = (e) => setMakerForm({...makerForm, isApproved: e.target.value})

    function logoutFunction() {
      return Axios
        .post(`auth/logout`)
        .then(async (res) => { })
        .catch((err) => {
          console.log(err);
        });
    }

    const executeMakerAction = async (e) => {
        let amlActionUpload = {
            docRef: makerForm.documentNumber,
            doc: makerForm.uploadDoc,
            ref: refId,
            notes: "-",
            action: "UPLOAD_DOCUMENT",
            customerType: "REGULAR",
        }

        let amlActionSubmit = {
            docRef: makerForm.documentNumber,
            doc: null,
            ref: refId,
            notes: makerForm.notes,
            action: makerForm.isApproved === "true"? "APPROVE": "REJECT",
            customerType: "REGULAR",
        }
        
        await executeAmlAction(amlActionUpload).then(res => {
            executeAmlAction(amlActionSubmit).then (res2 => {
                onSubmit(res2.data)
            }).catch(e => {
              let resData = {
                code: "FAILED",
                message: "Try again later"
              }
              onSubmit(resData)
            })
        }).catch( (err) => {
          let resData = {
            code: "FAILED",
            message: "Try again later"
          }
          console.log('hello, ',err)
          if(err.data.error === "invalid_token"){
            logoutFunction()
          }
          onSubmit(resData)
        })

        
    }

    const makerSubmit = (e) => {
        e.preventDefault()
        executeMakerAction(e);
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={handleCloseModalMaker}
            size="lg"
            centered
            onClosed={() => {
                setIsOpenModalMaker(false);
            }}>
                
        <ModalHeader
          toggle={handleCloseModalMaker}
          style={{ borderBottom: 'none' }}>
          <span style={{fontSize: '24px'}}>Tinjauan Pengajuan</span>
        </ModalHeader>

        <ModalBody >
          <Container>
            <Form onSubmit={makerSubmit}>
              <FormGroup>
                <Label for="notes">
                  Catatan
                </Label>
                <Input
                  id="notes"
                  name="notes"
                  placeholder="Masukkan catatan"
                  type="textarea"
                  onChange={handleNoteChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="docRef">
                  Nomor Dokumen (Opsional)
                </Label>
                <Input
                  id="docRef"
                  name="docRef"
                  placeholder="Masukkan nomor dokumen"
                  type="text"
                  onChange={handleDocumentNoChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="file">Upload Dokumen</Label>
                <Input
                  type="file"
                  name="uploadedDoc"
                  id="uploadedDoc"
                  accept=".pdf"
                  onChange={handleUpload}
                  required
                />
                <FormText color="muted">
                  Upload file penunjangmu di sini. Pastikan ekstensi file berjenis .pdf.
                </FormText>
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend style={{fontSize: "0.85rem", fontWeight: 500}}>Status persetujuan</legend>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="approved"
                      value="true"
                      checked={makerForm.isApproved === "true"}
                      onChange={handleApprovedChange}
                    />
                    {' '}
                    Approve
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="approved"
                      value="false"
                      checked={makerForm.isApproved === "false"}
                      onChange={handleApprovedChange}
                    />
                    {' '}
                    Reject
                  </Label>
                </FormGroup>
              </FormGroup>
              <div className="text-center mt-5">
                <Button type="submit" color="primary">Submit</Button>
              </div>
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    )

}

export default AmlModalMaker;